import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  TextField,
  Tooltip,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import moment from "moment";
import csvDownload from "json-to-csv-export";
// import Union2 from "assets/images/Union2.png";
import Colors from "../../../../assets/theme/base/colors";
// import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import DownloadIcon from "@mui/icons-material/Download";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import { Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import GetSurveyBasicList from "hooks/SurveyBasicList/GetSurveyBasicList";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";

const { grey } = Colors;

function SurveyList({ projectId }) {
  const navigate = useNavigate();
  const { setSurvey, setIsEdit } = useOutletContext();
  const { clients } = GetListOfClients();
  const { surveyStatusList } = GetSurveyStatusList();
  const { projectManager, setMangerSearch, mangerSearch } =
    GetProjectManagerList();
  const { language } = GetLanguageList();

  const [filterLanguage, setFilterLanguage] = useState([]);
  const [languageSearch, setLanguageSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const tableTheme = useTableTheme();

  const {
    surveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    languageFilter,
    setLanguageFilter,
    projectMangerFilter,
    setProjectMangerFilter,
    clientFilter,
    setClientFilter,
    filter,
    setFilter,
    dummyPage,
    setSortBy,
    setSortOrder
  } = GetSurveyBasicList(2, -1, projectId ? projectId : "");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50, //customize the default page size
  });

  useEffect(() => {
    setData(surveys);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id)
        setSortOrder("asc")
      }
      else {
        setSortBy(sorting[0].id)
        setSortOrder("desc")
      }
    }
    else if (sorting.length === 0) {
      setSortBy("id")
      setSortOrder("asc")
    }
  }, [surveys, pagination, sorting]);

  useEffect(() => {
    if (languageSearch === "") {
      setFilterLanguage(language);
    } else {
      setFilterLanguage(
        language?.filter((item) =>
          item.name.toLowerCase().includes(languageSearch.toLowerCase())
        )
      );
    }
  }, [languageSearch, language]);

  let columns = [
    {
      accessorKey: "id",
      header: "Id",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }) => {
        const id = cell.getValue(); 
        return id ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/surveys/${cell.getValue()}`);
            }}
          >
            {cell.getValue()}
          </div>
        ) : null;
      },
    },

    {
      accessorKey: "surveyName",
      header: "Survey Name",
      maxSize: 400,
      minSize: 180, 
      size: 180, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      enableSorting: false, 
      headerAlign: "center",
    },

    {
      accessorKey: "clientName",
      header: "Client Name",
      maxSize: 400,
      minSize: 180, 
      size: 180, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      enableSorting: false,
      headerAlign: "center",
    },

    {
      accessorKey: "sampleRequired",
      header: "Samp. Req.",
      maxSize: 400,
      minSize: 160, 
      size: 160, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
    },
    {
      accessorKey: "countryCode",
      header: "Country",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      enableSorting: false,
      headerAlign: "center",
    },
    {
      accessorKey: "loi",
      header: "Loi(mins)",
      maxSize: 400,
      minSize: 150, 
      size: 150, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
    },

    {
      accessorKey: "ir",
      header: "IR(%)",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
    },
    {
      accessorKey: "cpi",
      header: "CPI($)",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const cpi = parseFloat(cell.getValue());
        const roundedCpi = Math.round(cpi * 100) / 100;
        return roundedCpi ? roundedCpi.toFixed(2) : null;
      },
    },
    {
      accessorKey: "starts",
      header: "Starts",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
    },

    {
      accessorKey: "completes",
      header: "Completes",
      maxSize: 400,
      minSize: 160, 
      size: 160, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
    },
    {
      accessorKey: "epc",
      header: "EPC",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        return row
          ? parseFloat(
            row?.original?.starts <= 0
              ? 0
              : row?.original?.revenue / row?.original?.starts
          ).toFixed(2)
          : null;
      },
    },
    {
      accessorKey: "conversion",
      header: "Conv(%)",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        if (row) {
          return (
            <div style={{ width: "20px" }}>
              {parseFloat(
                row?.original?.starts <= 0
                  ? 0
                  : row?.original?.completes / row?.original?.starts
              ).toFixed(2)}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      accessorKey: "revenue",
      header: "Revenue",
      maxSize: 400,
      minSize: 140, 
      size: 140,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      }, 
      headerAlign: "center",
      Cell: ({ cell }) => {
        const revenue = parseFloat(cell.getValue());
        if (revenue) {
          const roundedRevenue = Math.round(revenue * 100) / 100;
          return roundedRevenue.toFixed(2);
        } else {
          return "NA";
        }
      },
    },

    {
      accessorKey: "createdAt",
      header: "Created at",
      maxSize: 400,
      minSize: 160, 
      size: 160, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const createdAtTime = moment(cell.getValue());
        const formattedDateTime = createdAtTime.format(
          "Do MMM YYYY, h:mm:ss a"
        );
        const truncatedName =
          formattedDateTime?.length > 8
            ? formattedDateTime?.substring(0, 8) + "..."
            : formattedDateTime;
        return data ? (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        ) : null;
      },
    },

    {
      accessorKey: "lastCompleted",
      header: "Last Complete",
      maxSize: 400,
      minSize: 180, 
      size: 180, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const lastCompletedTime = moment(cell.getValue());
        if (!lastCompletedTime.isValid() || data?.lastCompleted === null) {
          return <div>NA</div>;
        }
        const formattedDateTime = lastCompletedTime.format(
          "Do MMM YYYY, h:mm:ss a"
        );
        const truncatedName =
          formattedDateTime?.length > 8
            ? formattedDateTime?.substring(0, 8) + "..."
            : formattedDateTime;
        return data ? (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        ) : null;
      },
    },

    {
      accessorKey: "surveyStatusName",
      header: "Status",
      maxSize: 400,
      minSize: 140, 
      size: 140, 
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      headerAlign: "center",
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/surveys/${row.original.id}`);
      },
      sx: {
        cursor: "pointer", 
      },
    }),
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { pagination, sorting },
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    manualSorting: true,
    // state: { sorting },
    onSortingChange: setSorting,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
      },
    },
  });

  return (
    <>
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <MDBox pb={3} width="100%">
      <MDTypography
        color="secondary"
        ml="1%"
        sx={{ display: projectId ? "none" : "" }}
      >
        Surveys
      </MDTypography>
      <MDBox display="flex" justifyContent="center">
        <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
          <Grid container spacing={2} pb={3}>
            <Grid
              item
              px={2}
              xs={12}
              md={8}
              display="flex"
              flexDirection="row"
              sx={{ paddingLeft: "50px !important" }}
              gap="15px"
            >
              <Grid
                item
                xs={12}
                md={1.5}
                sx={{ display: projectId ? "none" : "" }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      fontWeight: "400",
                    },
                  }}
                >
                  <InputLabel>Language</InputLabel>
                  <Select
                    value={languageFilter}
                    onChange={(e) => {
                      setLanguageFilter(e.target.value);
                    }}
                    label="Language"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    onClose={() => setLanguageSearch("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        autoFocus
                        required
                        value={languageSearch}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setLanguageSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    <MenuItem value={-1}>All</MenuItem>
                    {filterLanguage?.map((value) => (
                      <MenuItem value={value.id} key={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={1.5}
                sx={{ display: projectId ? "none" : "" }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      fontWeight: "400",
                    },
                  }}
                >
                  <InputLabel>Project Manager</InputLabel>
                  <Select
                    value={projectMangerFilter}
                    onChange={(e) => {
                      setProjectMangerFilter(e.target.value);
                    }}
                    label="Project Manager"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    onClose={() => setMangerSearch("")}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        autoFocus
                        required
                        value={mangerSearch}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setMangerSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    <MenuItem value={-1}>All</MenuItem>
                    {projectManager?.map((value) => (
                      <MenuItem value={value.email} key={value.id}>
                        {value.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={1.5}
                sx={{ display: projectId ? "none" : "" }}
              >
                <FormControl fullWidth size="small">
                  <InputLabel>Clients</InputLabel>
                  <Select
                    value={clientFilter}
                    label="Clients"
                    onChange={(e) => {
                      setClientFilter(e.target.value);
                    }}
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    {clients?.map((value, i) => (
                      <MenuItem value={value.id} key={i}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={1.5}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={filter}
                    label="Status"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  >
                    <MenuItem value={-1}>All</MenuItem>
                    {surveyStatusList?.map((value, i) => {
                      return (
                        <MenuItem key={i} value={value.id}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <SearchInput setSearch={setSearch} search={search} />
            </Grid>
            <Grid
              px={2}
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
            >
              <IconButton
                sx={{
                  background: grey[100],
                  borderRadius: "4px !important",
                  display: projectId ? "none" : "",
                  "&:hover": {
                    background: grey[100],
                  },
                }}
                onClick={() => {
                  const dataToConvert = {
                    data: surveys,
                    filename: `SurveyPage${dummyPage}`,
                    delimiter: ",",
                  };
                  csvDownload(dataToConvert);
                }}
              >
                <DownloadIcon color="info" />
              </IconButton>
              <MDButton
                variant="text"
                color="info"
                size="small"
                onClick={() => {
                  navigate("/surveys/new");
                  setSurvey({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + New Survey
              </MDButton>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Grid>
          </Grid>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                table={table}
                style={{
                  width: "100%",
                }}
              />
            </ThemeProvider>
          </Stack>
        </MDBox>
      </MDBox>
    </MDBox>
    </>
  );
}

export default SurveyList;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetUserList = () => {
  const [user, setUser] = useState([]);
  const [userMap, setUserMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);

  const getUser = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`/users`);
      setUser(data.data);
      setTotalLength(data.data.lenght);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getUser();
  }, [page, search, entriesPerPage]);
  console.log(user);
  return {
    user,
    getUser,
    userMap,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
  };
};

export default GetUserList;

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Backdrop, CircularProgress, Icon, IconButton, Pagination, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import MDTypography from "components/MDTypography";
import GetCampaignList from "hooks/campaignsList/GetCampaignList";
import moment from "moment";
import { useSnackbar } from "notistack";

function CampaignsList() {
  const navigate = useNavigate();
  const { campaigns, totalLength, setPage, page, setSearch, search, isLoading } = GetCampaignList();
  const { setIsEdit, setCampaign } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Campaign url copy Successfully!", {
      variant: "success",
    });
  }

  let rows = campaigns;

  let columns = [
    {
      accessor: "id",
      Header: "Id",
      align: "left",
    },
    // {
    //   accessor: "accountId",
    //   Header: "AccountId",
    //   align: "center",
    //   Cell: ({ row }) => {
    //     return row?.values?.accountId ? row?.values?.accountId : "NA";
    //   },
    // },
    {
      accessor: "campaignGuid",
      Header: "Campaign Guid",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.campaignGuid;
        const truncatedName = name.length > 15 ? name.substring(0, 15) + "..." : name;
        return name ? (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      accessor: "campaignName",
      Header: "Campaign Name",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.campaignName;
        const truncatedName = name.length > 12 ? name.substring(0, 12) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "supplierName",
      Header: "Supplier Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        const name = row?.original?.supplierName;
        const truncatedName = name.length > 12 ? name.substring(0, 12) + "..." : name;
        return (
          <Tooltip title={name}>
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },

    {
      accessor: "countryCode",
      Header: "Country",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      accessor: "cpi",
      Header: "VCpi",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      accessor: "starts",
      Header: "Starts",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return row?.values?.starts?row.values.starts : "NA"; 
      },
    },
    {
      accessor: "completes",
      Header: "Completes",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return row?.values?.completes?row.values.completes : "NA"; 
      },
    },
    {
      accessor: "surveyId",
      Header: "SurveyID",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return row?.values?.surveyId?row.values.surveyId : "NA"; 
      },
    },
    {
      accessor: "startDate",
      Header: "Start Date",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return moment(row?.values?.startDate).format("MMM Do YY");
      },
    },
    {
      accessor: "endDate",
      Header: "End Date",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return moment(row?.values?.endDate).format("MMM Do YY");
      },
    },
    {
      accessor: "isActive",
      Header: "Status",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return row?.values?.isActive ? "Active" : "Inactive";
      },
    },
    {
      accessor: "campaignUrl",
      Header: "Url",
      sortable: true,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => {
        return row?.values?.campaignUrl ? (
          <Tooltip title={row.values.campaignUrl}>
            <a href={row?.values?.campaignUrl}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "120px",
                }}
              >
                {row?.values?.campaignUrl}
              </div>
            </a>
          </Tooltip>
        ) : (
          "NA"
        );
      },
    },
    {
      accessor: "action",
      Header: "Action",
      align: "center",
      headerAlign: "center",

      Cell: ({ row }) => (
        <Stack direction="row" spacing={2}>
          <Tooltip title={"Campaign Url"}>
            <IconButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => {
                copy(row.values.campaignUrl);
              }}
            >
              <Icon>copy</Icon>
            </IconButton>
          </Tooltip>
          <IconButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/campaigns/${row.values.id}`);
            }}
          >
            <Icon>visibility_icon</Icon>
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDTypography color="textColor" ml="1%">
        Campaigns
      </MDTypography>
      <MDBox display="flex" justifyContent="center">
        <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
          <Grid container spacing={2} pb={3}>
            <Grid
              px={2}
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <SearchInput setSearch={setSearch} search={search} />
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  navigate("/campaigns/new");
                  setCampaign({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + New Campaigns
              </MDButton>
            </Grid>
          </Grid>
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted
            noEndBorder
            entriesPerPage={false}
            canSearch={false}
          />
          {rows.length > 0 && (
            <Pagination
              count={Math.ceil(totalLength / 10)}
              variant="outlined"
              shape="rounded"
              style={{ margin: 20 }}
              color="info"
              page={page}
              onChange={(event, newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </MDBox>
      </MDBox>
    </>
  );
}

export default CampaignsList;

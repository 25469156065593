import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetLanguageConfig = (id, setIsLoading) => {
  const [language, setLanguage] = useState([]);
  const [sup_id, setSup_id] = useState(id);
 
  const getLanguage = async () => {
    if (sup_id === "") {
      setIsLoading(true);
    }
    try {
      setIsLoading(true);
      let data = await TLinkApi.get(`/vendors/language/${sup_id}`);
      setLanguage(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLanguage();
  }, [sup_id]);
  return {
    language,
    sup_id,
    setSup_id,
  };
};

export default GetLanguageConfig;

import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import Axios from "axios";

function UserForgotPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { 
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  // const save = async (value) => {
  //   try {
  //     const { data, status } = await Axios.post(TLinkApi.baseUrl + "/login", value);
  //     if (status === 200) {
  //       Auth.saveAuthorizationToken(data?.token);
  //       enqueueSnackbar("Logged in", {
  //         variant: "success",
  //       });
  //       navigate("/dashboard");
  //     }
  //   } catch (err) {
  //     enqueueSnackbar(err?.response?.data?.message, {
  //       variant: "error",
  //     });
  //     // alert("Login failed. Please check your credentials!");
  //   }
  // };

  const save = async (requestData) => {
    try {
      const { data: responseData, status } = await Axios.post(
        TLinkApi.baseUrl + "/forgetPassword/sendMail",
        requestData
      );
      if (status === 200) {
        enqueueSnackbar(responseData?.message, {
          variant: "success",
        });
        // navigate("/dashboard");
      }
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
      // alert("Login failed. Please check your credentials!");
    }
  };
  

  const handleFormSubmit = (data) => {
    enqueueSnackbar("Please check your Email for further instructions.", {
      variant: "success",
    });
  };

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email to reset your password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(save)} noValidate>
            <MDBox mb={2}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    required
                    type="email"
                    label="Email"
                    {...register("email")}
                   // variant="standard"
                    placeholder="Input Email"
                    error={errors?.email ? true : false}
                    helperText={errors?.email ? errors.email.message : null}
                  />
                )}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Submit
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Remember your password?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default UserForgotPassword;

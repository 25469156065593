import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import colors from "assets/theme/base/colors";

function UserBasicDetails() {
  const { formDetailsColor } = colors;

  const navigate = useNavigate();
  const { user, setUser, setIsEdit, isEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      roleId: false,
      isActive: false,
    },
  });

  let isActive = watch("isActive");
  // let userStatusId = watch("roleId");

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
      setValue("firstName", user.firstName);
      setValue("isActive", user.isActive);
      setValue("lastName", user.lastName);
      setValue("roleId", user.roleId);
      setValue("lastName", user.lastName);
    }
  }, [user]);

  const { id } = useParams();

  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/users/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/users/${id}`);

          setUser(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/users/${user.id}`, data);
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        setUser({});

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/users`);
      } else {
        let result = await TLinkApi.post("/users", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setUser({});

        navigate(`/users`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          style={{
            borderRadius: "16px",
            background: "white",
            minHeight: "300px",
          }}
          p={3}
          m={2}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2}>
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  navigate(`/users/${id}/ `);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mb={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"User Details"}
            </MDTypography>
            <Grid container spacing={3} pt={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "First Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      label="First name"
                      autoComplete="off"
                      {...register("firstName", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.firstName ? true : false}
                      helperText={errors?.firstName ? errors.firstName.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Last Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      label="Last name"
                      autoComplete="off"
                      {...register("lastName", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.lastName ? true : false}
                      helperText={errors?.lastName ? errors.lastName.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      disabled={isEdit.disabled}
                      {...field}
                      fullWidth
                      label="Email Address"
                      required
                      {...register("email", {
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      autoComplete="off"
                      variant="outlined"
                      // disabled={isEdit.disabled}
                      error={errors?.email ? true : false}
                      helperText={errors?.email ? errors.email.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="roleId"
                  control={control}
                  rules={{
                    required: "Status is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.roleId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Role</InputLabel>
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        // sx={selectStyle}
                        label="Role"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        <MenuItem value="">
                          <em>Role</em>
                        </MenuItem>
                        <MenuItem value={1}>Admin</MenuItem>
                        <MenuItem value={2}>Manager</MenuItem>
                      </Select>
                      {errors?.status && (
                        <FormHelperText>{errors?.roleId ? errors.roleId.message : null}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} mt={1} display="flex" flexWrap="wrap">
              <FormControlLabel
                control={
                  <Switch
                    disabled={isEdit.disabled || user.userStatusId === 1}
                    checked={user.userStatusId === 1 ? false : isActive}
                    onChange={(e, value) => {
                      setValue("isActive", value);
                    }}
                  />
                }
                label={
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Is Active&nbsp;
                  </MDTypography>
                }
              />
            </Grid>
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox pb={2} px={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setUser({});
                navigate("/users");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox pb={2} px={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setUser({});
              navigate("/users");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              navigate(`/users/${id}/userEncryption/edit`);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      )}
    </>
  );
}

export default UserBasicDetails;

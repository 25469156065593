import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import MDBox from "components/MDBox";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import colors from "assets/theme/base/colors";

const AddSupplier = ({ open, setOpen, getTableData, vendorsList, survey }) => {
  const [isLoading, setIsLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  let { textColor, formDetailsColor } = colors;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: {
      supplierId: "",
      allocation: "",
      vcpi: "",
      allocatedValue: "",
    },
  });

  let { id } = useParams();

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      vendorId: data.supplierId,
      allocation: parseFloat(data.allocation),
      vcpi: parseFloat(data.vcpi),
      allocatedValue: parseFloat(
        (survey.sampleRequired * watch("allocation")) / 100
      ),
      surveyId: parseInt(id),
    };
    try {
      let result = await TLinkApi.post("/survey/supplier/add", payload);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      setValue("supplierId", "");
      setValue("allocation", "");
      setValue("vcpi", "");
      setValue("allocatedValue", "");
      handleClose();
      getTableData();
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const handleClose = (e) => {
    setValue("supplierId", "");
    setValue("allocation", "");
    setValue("vcpi", "");
    setValue("allocatedValue", "");
    setOpen(false);
  };

  // const ingerNum = (e) => {
  //   const characterCode = e.key;
  //   if (characterCode === "Backspace") return;

  //   const characterNumber = Number(characterCode);
  //   if (characterNumber >= 0 && characterNumber <= 9) {
  //     return;
  //   } else {
  //     e.preventDefault();
  //   }
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle sx={{ color: textColor.main, fontSize: "18px" }}>
        {"Add Supplier"}
        <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
      </DialogTitle>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox px={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Controller
                name="supplierId"
                control={control}
                rules={{
                  required: "SupplierId is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors?.supplierId ? true : false}
                    sx={{
                      label: {
                        color: `${formDetailsColor.primary}`,
                        fontWeight: "400",
                      },
                    }}
                    size="small"
                  >
                    <InputLabel>Supplier List</InputLabel>
                    <Select
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Supplier Name"
                      placeholder="Supplier List"
                      sx={selectStyle}
                    >
                      {vendorsList?.map((value, i) => {
                        return (
                          <MenuItem value={value.id} key={i}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.supplierId && (
                      <FormHelperText>
                        {errors?.supplierId ? errors.supplierId.message : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="vcpi"
                control={control}
                rules={{
                  required: "vcpi is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    required
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    label="vcpi"
                    error={errors?.vcpi ? true : false}
                    helperText={errors?.vcpi ? errors.vcpi.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                name="allocation"
                control={control}
                rules={{
                  required: "Allocation is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    fullWidth
                    required
                    label="Allocation(%)"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    type="number"
                    {...register("allocation", {
                      min: 0,
                      max: 100,
                    })}
                    autoComplete="off"
                    // onKeyPress={(e) => {
                    //   ingerNum(e);
                    // }}
                    variant="outlined"
                    error={errors?.allocation ? true : false}
                    helperText={
                      errors?.allocation && errors.allocation.message !== ""
                        ? errors.allocation.message
                        : errors?.allocation?.type === "min"
                          ? "Allocation should not be less then 0"
                          : errors?.allocation?.type === "max"
                            ? "Allocation should not be more then 100"
                            : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                fullWidth
                value={(survey.sampleRequired * watch("allocation")) / 100}
                disabled={true}
                autoComplete="off"
                variant="outlined"
                label="Allocation"
              />
            </Grid>
          </Grid>
        </MDBox>
        <DialogActions>
          <MDButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => {
              handleClose();
            }}
          >
            &nbsp;cancel
          </MDButton>

          <MDButton variant="gradient" color="info" size="small" type="submit">
            ADD
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddSupplier;

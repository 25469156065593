import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export default styled(TextField)(({ theme, ownerState }) => {
  const { palette, functions } = theme;
  const { error, success, disabled } = ownerState;

  const { transparent, error: colorError, success: colorSuccess } = palette;
  const { pxToRem } = functions;
  const errorStyles = () => ({
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: colorError.main,
      },
    },

    ".MuiOutlinedInput-notchedOutline": {
      borderColor: colorError.main,
    },

    label: {
      color: colorError.main,
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: colorError.main,
    },

    ".MuiFormHelperText-root": {
      color: colorError.main,
    },
  });

  // styles for the input with success={true}
  const successStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%234CAF50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `right ${pxToRem(12)} center`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,

    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline, &:after": {
        borderColor: colorSuccess.main,
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: colorSuccess.main,
    },
  });

  return {
    backgroundColor: disabled ? `#f0f0f0 !important` : transparent.main,
    "& .MuiOutlinedInput-notchedOutline": { border: "1px solid #D9D9D9", borderRadius: "0px" },
    label: {
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "400",
    },
    "& input::placeholder": {
      color: "rgba(0, 0, 0, 1)",
      fontWeight: "400",
    },

    pointerEvents: disabled ? "none" : "auto",
    ...(error && errorStyles()),
    ...(success && successStyles()),
  };
});

import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";

function UserUpdatePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [resetSuccess, setResetSuccess] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleFormSubmit = (data) => {
    // Your password reset logic goes here...
    // You can access the old password, new password, and confirmed password using data.oldPassword, data.password, and data.confirmPassword respectively.

    enqueueSnackbar("Password has been updated successfully.", {
      variant: "success",
    });
  };

  const confirmPassword = watch("confirmPassword");

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Update Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your old password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Confirm password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
            <MDBox mb={2}>
              <Controller
                name="oldPassword"
                control={control}
                rules={{
                  required: "Old Password is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Old Password"
                    fullWidth
                    error={errors?.oldPassword ? true : false}
                    helperText={errors?.oldPassword ? errors.oldPassword.message : null}
                  />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="New Password"
                    fullWidth
                    error={errors?.password ? true : false}
                    helperText={errors?.password ? errors.password.message : null}
                  />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm Password is required!",
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Your passwords do not match";
                    }
                  },
                }}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    required
                    error={errors?.confirmPassword ? true : false}
                    helperText={errors?.confirmPassword ? errors.confirmPassword.message : null}
                  />
                )}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Update Password
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default UserUpdatePassword;

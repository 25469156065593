import Grid from "@mui/material/Grid";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import "../../../../../../global.css";
import StatusTable from "./statusTable";
import GetReconciliationTypeList from "hooks/ReconciliationTypeList/GetReconciliationTypeList";
import CircularStatic from "comps/progressWithLabel/CircularProgressWithLabel";
import MDTypography from "components/MDTypography";
import UploadIcon from "@mui/icons-material/Upload";
import ReconciliationList from "layouts/projects/components/reconciliation/components/reconciliationList";
import xlsx from "json-as-xlsx";
import sampleData from "assets/DummyCSV/xlsxSampleFile";
import Colors from "../../../../../../assets/theme/base/colors";
import ExcelJS from "exceljs";
const { formDetailsColor, info } = Colors;

const AddReconciliation = () => {
  const navigate = useNavigate();
  const { setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  let { reconciliationType } = GetReconciliationTypeList();
  let { id, reconcileId } = useParams();
  const [reconcileData, setReconcileData] = useState({});

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      reconcileTypeId: "",
      file: "",
    },
  });

  let file = watch("file");

  let settings = {
    fileName: "SampleFile",
    extraLength: 3,
    writeMode: "writeFile",
    writeOptions: {},
    RTL: false,
  };

  useEffect(() => {
    getData();
  }, [reconcileId]);

  const getData = async () => {
    if (reconcileId) {
      if (reconcileId !== "new") {
        setIsLoading(true);
        try {
          let result = await TLinkApi.get(`/reconciliation/${reconcileId}`);
          if (result) {
            setIsLoading(false);
            setValue("reconcileTypeId", result.data.reconciliationData.reconciliationType);
            setReconcileData(result.data);
          }
        } catch (e) {
          setIsLoading(false);
          console.log(e);
        }
      }
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  const save = async (data) => {
    var ext = getExtension(data.file.name);
    if (ext !== "csv" && ext !== "xls" && ext !== "xlsx") {
      enqueueSnackbar("Please upload csv, xls or xlsx formate file", {
        variant: "error",
      });
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("file", data.file);
      formData.append("reconcileTypeId", data.reconcileTypeId);
      formData.append("projectId", id);

      try {
        let result = await TLinkApi.upload("/reconciliation", formData);
        setIsLoading(false);
        // enqueueSnackbar("Upload Successfully", {
        //   variant: "success",
        // });
        navigate(`/projects/${id}/reconciliations/${result.data.id}`);
      } catch (e) {
        setIsLoading(false);
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    setIsEdit({
      disabled: false,
      edit: true,
    });
  }, []);

  const reconciliation = async (projectClose) => {
    enqueueSnackbar("Reconciliation Done", {
      variant: "success",
    });
    setIsLoading(true);
    try {
      await TLinkApi.post("/reconciliation/reconciliation-click", {
        reconcileId: reconcileId,
        projectClose: projectClose,
      });
      setReconcileData({});
      setValue("file", "");
      setValue("reconcileTypeId", "");
      navigate(`/projects/${id}/reconciliations/new`);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        p={2}
        style={{
          borderRadius: "16px",
          minHeight: "700px",
          background: "white",
        }}
        m={2}
      >
        <form onSubmit={handleSubmit(save)} noValidate>
          <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}>
            <MDTypography variant="h6" fontWeight="medium" color="textColor" mb={1}>
              {" Add Reconciliation"}
            </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Controller
                  name="reconcileTypeId"
                  control={control}
                  rules={{
                    required: "Reconcile Type is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.reconcileTypeId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Reconcile Type</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={selectStyle}
                        label="Reconcile Type"
                        disabled={Object.keys(reconcileData).length > 0}
                      >
                        {reconciliationType.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.reconcileTypeId && (
                        <FormHelperText>
                          {errors?.reconcileTypeId ? errors.reconcileTypeId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {/* {Object.keys(reconcileData).length === 0 && ( */}
                <>
                  <Controller
                    name="file"
                    control={control}
                    rules={{
                      required: "File is required!",
                    }}
                    render={({ field: { onChange } }) => (
                      <>
                        <MDButton
                          variant="outlined"
                          color="info"
                          fullWidth
                          size="small"
                          disabled={Object.keys(reconcileData).length > 0}
                          style={{
                            borderColor: errors?.file && "red",
                            height: "36px",
                          }}
                          htmlFor="files"
                          onClick={() => {
                            document.getElementById("files").click();
                          }}
                          // startIcon={<Icon>get_app_icon</Icon>}
                          startIcon={<UploadIcon />}
                        >
                          {file !== "" ? file.name : "Click to Upload"}
                        </MDButton>
                        <input
                          id="files"
                          onChange={(e) => {
                            onChange(e.target.files[0]);
                          }}
                          required
                          disabled={Object.keys(reconcileData).length > 0}
                          type="file"
                          style={{ display: "none" }}
                          label="File Upload"
                          autoComplete="off"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          variant="outlined"
                        />
                        {errors?.file && (
                          <FormHelperText style={{ color: errors?.file && "red" }}>
                            {errors?.file ? errors.file.message : null}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </>
                {/* )} */}
              </Grid>
              <Grid item xs={12} md={3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  disabled={Object.keys(reconcileData).length > 0}
                >
                  Continue
                </MDButton>
              </Grid>
              <Grid item xs={12} md={3} justifyContent={"end"}>
                <Grid container justifyContent={"right"}>
                  <MDButton
                    variant="contained"
                    color="info"
                    onClick={async () => {
                      const tokens = ["22e8728645234140abff54fbbd7632f9", "609405b5e4b548109bc7fb004c8be675"];

                      const workbook = new ExcelJS.Workbook();

                      const worksheet = workbook.addWorksheet("Sheet1");

                      tokens.forEach((token) => {
                        worksheet.addRow([token]);
                      });

                      const blob = await workbook.xlsx.writeBuffer();

                      const link = document.createElement("a");

                      link.href = URL.createObjectURL(new Blob([blob]));

                      link.download = "SampleFile.xlsx";

                      document.body.appendChild(link);

                      link.click();

                      document.body.removeChild(link);
                    }}
                  >
                    Sample xlsx
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
          {Object.keys(reconcileData).length <= 0 && <ReconciliationList />}

          {Object.keys(reconcileData).length > 0 && (
            <Grid item xs={12} md={12} marginTop={"10px"}>
              <MDBox className="reconciliation-status-box">
                Status:
                <CircularStatic
                  value={reconcileData.total === 0 ? 0 : (reconcileData.totalMatched / reconcileData.total) * 100}
                />
                <MDBox className="reconciliation-all">
                  <MDBox>All</MDBox>
                  <MDBox>
                    {reconcileData.total}/{reconcileData.total}
                  </MDBox>
                </MDBox>
                <MDBox className="reconciliation-match">
                  <MDBox>Matched</MDBox>
                  <MDBox>
                    {reconcileData.totalMatched}/{reconcileData.total}
                  </MDBox>
                </MDBox>
                <MDBox className="reconciliation-unmatch">
                  <MDBox>Unmatched</MDBox>
                  <MDBox>
                    {reconcileData.totalUnMatched}/{reconcileData.total}
                  </MDBox>
                </MDBox>
              </MDBox>
              <StatusTable reconcileData={reconcileData.rowData} />
            </Grid>
          )}
        </form>
      </MDBox>

      {Object.keys(reconcileData).length > 0 && (
        <MDBox
          px={2}
          mb={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            flexDirection: {
              md: "column",
              lg: "row",
            },
          }}
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setReconcileData({});
              // window.location.reload();
              setValue("file", "");
              setValue("reconcileTypeId", "");
              navigate(`/projects/${id}/reconciliations/new`);
              window.location.reload();
            }}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            disabled={reconcileData.reconciliationData.status !== 1}
            onClick={() => {
              let projectClose = false;
              reconciliation(projectClose);
              window.location.reload();
            }}
          >
            Reconcile
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              let projectClose = true;
              reconciliation(projectClose);
              navigate(`/projects/${id}/reconciliations/new`);
            }}
          >
            Reconcile & Close
          </MDButton>
        </MDBox>
      )}
    </>
  );
};

export default AddReconciliation;

import MDBox from "components/MDBox";
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { IconButton, Tab, Tabs } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";

function AddCampaigns() {
  const { campaign, setCampaign, setIsEdit, isEdit } = useOutletContext();
  const location = useLocation();
  let { id } = useParams();
  const [value, setValue] = useState(0);
  let { textColor } = colors;
  let navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === `/campaigns/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/campaigns/new`) {
      setValue(0);
    } else if (window.location.pathname === `/campaigns/${id}/report`) {
      setValue(1);
    }
  }, [location]);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            setCampaign({});
            navigate("/campaigns");
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id ? id : "New Campaigns"}
      </MDTypography>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab
          label="Campaigns Details"
          index={0}
          component={Link}
          to={id ? `/campaigns/${id}/edit` : `/campaigns/new`}
        />
        <Tab
          label="Campaigns Report"
          index={0}
          component={Link}
          to={id ? `/campaigns/${id}/report` : `/campaigns/new`}
        />
      </Tabs>
      <Outlet
        context={{
          campaign,
          setCampaign,
          setIsEdit,
          isEdit,
        }}
      />
    </MDBox>
  );
}

export default AddCampaigns;

import { useState, useEffect } from "react";
import { Box, Button, Backdrop, CircularProgress } from "@mui/material";
import { Modal } from "./LanguageListTableEdit";
import DisplayTable from "./languageListTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import GetLanguageConfig from "hooks/GetLanguageConfig/getLanguageConfig";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import { useSnackbar } from "notistack";

function LanguageList() {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [languageMap, setLanguageMap] = useState([]);
  const [selectlanguage, setSelectlanguage] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

  let { id } = useParams();

  let supplierid = id;
  const navigate = useNavigate();
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();

  const { language, sup_id, setSup_id } = GetLanguageConfig(
    supplierid,
    setIsLoading
  );
  async function GetLanguageList() {
    let languagelist = await TLinkApi.get("/masters/language");
    let langMap = [];
    languagelist.data.map((value) => {
      let object = { id: value.id, languagedesc: value.name };
      langMap.push(object);
    });
    setLanguageMap(langMap);
  }
  useEffect(() => {
    GetLanguageList();
    let displayrows = [];
    language.map((lang) => {
      if (selectlanguage.indexOf(String(lang.languageId)) === -1) {
        selectlanguage.push(lang.languageId);
      }
      let obj = {
        isActive: lang.isActive,
        id: lang.id,
        languageId: lang.languageId,
        languageDesc: lang.languageDesc,
        minEpc: lang.minEpc,
      };
      displayrows.push(obj);
    });
    setRows(displayrows);
  }, [language]);

  const putdata = async (data, id) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.put(`/vendors/language/${id}`, data);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const postdata = async (data) => {
    setIsLoading(false);
    try {
      let result = await TLinkApi.post(`/vendors/language/`, data);
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      return result;
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const deletedata = async (targetid) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.del(`/vendors/language/${targetid}`, {});
      setIsLoading(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const handleDeleteRow = (targetid, languageId) => {
    console.log(targetid);
    if (selectlanguage.indexOf(String(languageId)) > -1) {
      selectlanguage.splice(selectlanguage.indexOf(String(languageId)), 1);
    }
    setRows(rows.filter((row) => row.id !== targetid));
    deletedata(targetid);
  };

  const handleEditRow = (targetid) => {
    var index = rows.findIndex((row) => row.id === targetid);
    setRowToEdit(index);
    setModalOpen(true);
  };

  const handleSubmit = (newRow) => {
    if (rowToEdit === null) {
      if (selectlanguage.indexOf(String(newRow.languageId)) === -1) {
        selectlanguage.push(newRow.languageId);
      }
      let object = {
        languageId: newRow.languageId,
        vendorId: supplierid,
        isActive: true,
        minEpc: newRow.minEpc,
        createdBy: 1,
        updatedBy: 1,
      };
      let response = postdata(object);
      response.then((data) => {
        newRow.id = data.data.id;
        setRows([...rows, newRow]);
      });
    } else {
      setRows(
        rows.map((currRow, idx) => {
          if (idx !== rowToEdit) {
            return currRow;
          } else {
            let object = {
              languageId: newRow.languageId,
              minEpc: newRow.minEpc,
              createdBy: 1,
              updatedBy: 2,
            };
            putdata(object, newRow.id);
            return newRow;
          }
        })
      );
    }
  };

  const handlesave = () => {
    setIsEdit({
      disabled: false,
      edit: true,
    });
    navigate("/vendors");
    setVendor({});
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box px={2} mb={4} mt={2}>
        <div className="App">
          <Button
            sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "0",
              variant: "contained",
              color: "primary",
              fontSize: 13,
            }}
            onClick={() => setModalOpen(true)}
          >
            + Add row
          </Button>
          <MDBox>
            <DisplayTable
              rows={rows}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
            />
          </MDBox>
          {modalOpen && (
            <Modal
              closeModal={() => {
                setModalOpen(false);
                setRowToEdit(null);
              }}
              onSubmit={handleSubmit}
              languageMap={languageMap}
              selectlanguage={selectlanguage}
              defaultValue={rowToEdit !== null && rows[rowToEdit]}
            />
          )}
          <MDBox
            px={2}
            mb={4}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              sx={{ marginTop: "10px" }}
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
                navigate("/vendors");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              sx={{ marginTop: "10px" }}
              variant="gradient"
              color="info"
              type="submit"
              onClick={() => {
                navigate(`/vendors/${id}/clientbuyerconfig/edit`);
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
              }}
            >
              Save
            </MDButton>
          </MDBox>
        </div>
      </Box>
    </>
  );
}

export default LanguageList;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import Axios from "axios";

const UserStatusList = () => {
  const [userStatusList, setUserStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const GetUserStatusList = async () => {
    try {
      const { data, status } = await TLinkApi.get("/masters/user_status_list");
      setUserStatusList(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  console.log(userStatusList);
  useEffect(() => {
    GetUserStatusList();
  }, []);
  return {
    userStatusList,
    GetUserStatusList,
    isLoading,
  };
};

export default UserStatusList;

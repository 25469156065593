import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

  const GetSurveyBasicList = (filterValue, clientId, projectId) => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(filterValue);
  const [clientFilter, setClientFilter] = useState(clientId);
  const [projectMangerFilter, setProjectMangerFilter] = useState(-1);
  const [languageFilter, setLanguageFilter] = useState(-1);
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getSurveys = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    if (sortBy == 'surveyName' || sortBy == 'clientName' || sortBy == 'countryCode') {
      console.log("Return -- NO string request");
    } else {
      try {
        let data = await TLinkApi.get(
          `/survey?search=${search}&filter=${filter}&page=${page + 1}&limit=${entriesPerPage}&clientFilter=${clientFilter}&languageFilter=${languageFilter}&projectManagerFilter=${projectMangerFilter}&projectId=${projectId}&sortOrder=${sortOrder}&sortBy=${sortBy}`
        );
        setSurveys(data.data.surveyData);
        setTotalLength(data.data.totalLenght);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getSurveys();
  }, [page, search, filter, clientFilter, entriesPerPage, sortBy, sortOrder, projectMangerFilter, languageFilter]);

  return {
    surveys,
    getSurveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setFilter,
    clientFilter,
    setClientFilter,
    projectMangerFilter,
    setProjectMangerFilter,
    languageFilter,
    setLanguageFilter,
    filter,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    setIsLoading,
  };
};

export default GetSurveyBasicList;

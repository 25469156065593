import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetCampaignReportList = (id) => {
  const [campaignReport, setCampaignReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);

  const getCampaignReport = async () => {
    if (search === "") {
      setIsLoading(true);
    }

    try {
      let data = await TLinkApi.get(`/campaign/${id}/campaigns-report?page=${page}&limit=${entriesPerPage}`);
      setCampaignReport(data.data.data);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getCampaignReport();
  }, [page, search, entriesPerPage]);

  return {
    campaignReport,
    setCampaignReport,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setIsLoading,
  };
};

export default GetCampaignReportList;

import MDBox from "components/MDBox";
import { Link, Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconButton, Tab, Tabs } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import TLinkApi from "services/tlink.api";

function AddVendors() {
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();
  const [value, setValue] = useState(0);
  const location = useLocation();
  let { id } = useParams();
  let { textColor } = colors;
  let navigate = useNavigate();

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (window.location.pathname === `/vendors/${id}/redirectionType/edit`) {
      setValue(1);
    } else if (window.location.pathname === `/vendors/${id}/vendorEncryption/edit`) {
      setValue(2);
    } else if (window.location.pathname === `/vendors/${id}/routingConfig/edit`) {
      setValue(3);
    } else if (window.location.pathname === `/vendors/${id}/suppliermargin/edit`) {
      setValue(4);
    } else if (window.location.pathname === `/vendors/${id}/languagelist/edit`) {
      setValue(5);
    } else if (window.location.pathname === `/vendors/${id}/clientbuyerconfig/edit`) {
      setValue(6);
    } else if (window.location.pathname === `/vendors/${id}/vendorSupplyConfig/edit`) {
      setValue(7);
    } else if (window.location.pathname === `/vendors/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/vendors/new`) {
      setValue(0);
    }
  }, [location]);

  let getVendor = async () => {
    try {
      let result = await TLinkApi.get(`/vendors/${id}`);
      setVendor(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getVendor();
    }
  }, [id]);

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            setVendor({});
            navigate("/vendors");
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id ? (vendor && vendor?.name ? vendor?.name + "  " + "(" + id + ")" : id) : "New Vendor"}
      </MDTypography>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab label="Vendor Details" index={0} component={Link} to={id ? `/vendors/${id}/edit` : `/vendors/new`} />
        <Tab label="Redirection" index={1} component={Link} disabled={!id} to={`/vendors/${id}/redirectionType/edit`} />
        <Tab
          label="Vendors Encryption"
          index={2}
          component={Link}
          to={`/vendors/${id}/vendorEncryption/edit`}
          disabled={!id}
        />
        <Tab
          label="Routing"
          index={3}
          sx={{ display: vendor && !vendor?.isApi ? "none" : "" }}
          component={Link}
          to={`/vendors/${id}/routingConfig/edit`}
          disabled={!id}
        />
        <Tab
          label="Supplier Margin"
          index={4}
          component={Link}
          sx={{ display: vendor && !vendor?.isApi ? "none" : "" }}
          to={`/vendors/${id}/suppliermargin/edit`}
          disabled={!id}
        />
        <Tab
          label="Language Config"
          index={5}
          component={Link}
          sx={{ display: vendor && !vendor?.isApi ? "none" : "" }}
          to={`/vendors/${id}/languagelist/edit`}
          disabled={!id}
        />
        <Tab
          label="Client Buyer Config"
          index={6}
          component={Link}
          sx={{ display: vendor && !vendor?.isApi ? "none" : "" }}
          to={`/vendors/${id}/clientbuyerconfig/edit`}
          disabled={!id}
        />
        <Tab
          label="Vendor Supply Config"
          index={7}
          component={Link}
          sx={{ display: vendor && !vendor?.isApi ? "none" : "" }}
          to={`/vendors/${id}/vendorSupplyConfig/edit`}
          disabled={!id}
        />
      </Tabs>
      <Outlet context={{ vendor, setVendor, setIsEdit, isEdit }} />
    </MDBox>
  );
}

export default AddVendors;

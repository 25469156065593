import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

function GetSurveyCategoryList() {
  const [surveyCategory, setSurveyCategory] = useState([]);
  const getSurveyCategory = async () => {
    try {
      let data = await TLinkApi.get("/masters/surveycategory");
      setSurveyCategory(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getSurveyCategory();
  }, []);
  return { surveyCategory, getSurveyCategory };
}

export default GetSurveyCategoryList;

import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Stack, useMediaQuery } from "@mui/material";

function DataTableHeadCell({
  width,
  children,
  sortOrder,
  setSortBy,
  setSortOrder,
  column,
  sortBy,
  sorted,
  align,
  ...rest
}) {
  const matches = useMediaQuery("(max-width:1500px)");
  return (
    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `2px solid #f0f0f0`,
      })}
    >
      {setSortOrder === undefined && setSortBy === undefined ? (
        <MDBox
          {...rest}
          textAlign={align}
          color="textColor"
          position="relative"
          opacity={0.7}
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: matches ? "8px" : size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            cursor: sorted && "pointer",
            userSelect: sorted && "none",
          })}
        >
          <Stack
            direction="row"
            spacing={0}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent={align}
          >
            <div>{children}</div>
            {sorted && (
              <MDBox
                position="relative"
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox
                  position="absolute"
                  top={-6}
                  color={sortOrder === "asce" ? "text" : sorted === "asce" ? "text" : "secondary"}
                  opacity={sortOrder === "asce" ? 1 : sorted === "asce" ? 1 : 0.5}
                >
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox
                  position="absolute"
                  top={0}
                  color={sortOrder === "desc" ? "text" : sorted === "desc" ? "text" : "secondary"}
                  opacity={sortOrder === "desc" ? 1 : sorted === "desc" ? 1 : 0.5}
                >
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </Stack>
        </MDBox>
      ) : (
        <MDBox
          textAlign={align}
          color="textColor"
          position="relative"
          opacity={0.7}
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: matches ? "8px" : size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            cursor: sorted && "pointer",
            userSelect: sorted && "none",
          })}
          onClick={() => {
            if (column?.sortable) {
              if (sortOrder === "desc") {
                setSortBy(column.id);
                setSortOrder("asc");
              } else {
                setSortBy(column.id);
                setSortOrder("desc");
              }
            }
          }}
        >
          <Stack
            direction="row"
            spacing={0}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent={align}
          >
            <div>{children}</div>
            {column?.sortable && (
              <MDBox
                position="relative"
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox
                  position="absolute"
                  top={-6}
                  color={sortOrder === "asc" && column.id === sortBy ? "text" : "secondary"}
                  opacity={sortOrder === "asc" && column.id === sortBy ? 1 : 0.5}
                >
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox
                  position="absolute"
                  top={0}
                  color={sortOrder === "desc" && column.id === sortBy ? "text" : "secondary"}
                  opacity={sortOrder === "desc" && column.id === sortBy ? 1 : 0.5}
                >
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </Stack>
        </MDBox>
      )}
    </MDBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;

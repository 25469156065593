import SurveyList from "layouts/survey/components/surveyList";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useNavigate, useOutletContext } from "react-router-dom";

function SurveyProjectList() {
  let { id } = useParams();

  return (
    <>
      <SurveyList projectId={id} />
    </>
  );
}

export default SurveyProjectList;

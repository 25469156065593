import { Backdrop, Box, CircularProgress, Stack } from "@mui/material";
import { DisplayTable } from "./clientBuyerConfigTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import ClientBuyerFormModal from "./clientBuyerFormModal";
import TLinkApi from "services/tlink.api";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function ClientBuyerConfig() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();

  const navigate = useNavigate();

  let { id } = useParams();
  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  let { enqueueSnackbar } = useSnackbar();

  let getData = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`vendors/client/buyers/${id}`);
      setData(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteRow = async (rowId) => {
    try {
      await TLinkApi.del(`vendors/${id}/client/buyers/${rowId}`);
      enqueueSnackbar("Sucessfully Deleted", {
        variant: "success",
      });
      getData();
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Opps Somthing went wrong", {
        variant: "error",
      });
    }
  };

  const handleEditRow = (row) => {
    setOpen(true);
    setEditData(row);
  };

  // const handlesave = () => {
  //   setIsEdit({
  //     disabled: false,
  //     edit: true,
  //   });
  //   navigate(`/vendors/${id}/vendorSupplyConfig/edit`);
  // };

  const handlesave = () => {
    setIsEdit({
      disabled: false,
      edit: true,
    });
    navigate("/vendors");
    setVendor({});
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box px={2} mb={4} mt={2}>
        {data.length < 25 && (
          <Stack direction="row" justifyContent="flex-end" width="100%">
            <MDButton
              variant="text"
              size="small"
              color="info"
              onClick={() => setOpen(true)}
            >
              + Add Row
            </MDButton>
          </Stack>
        )}
        <MDBox>
          <DisplayTable
            rows={data}
            deleteRow={handleDeleteRow}
            editRow={handleEditRow}
          />
        </MDBox>
        <ClientBuyerFormModal
          open={open}
          handleClose={handleClose}
          editData={editData}
          getData={getData}
          data={data}
        />

        <MDBox
          px={2}
          mb={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            sx={{ marginTop: "10px" }}
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
              navigate("/vendors");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            sx={{ marginTop: "10px" }}
            variant="gradient"
            color="info"
            type="submit"
            onClick={() => {
              navigate(`/vendors/${id}/vendorSupplyConfig/edit`);
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
            }}
          >
            Save
          </MDButton>
        </MDBox>
      </Box>
    </>
  );
}

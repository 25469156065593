import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetRoutingStatus = () => {
  const [routingStatusValue, setRoutingStatusValue] = useState([]);
  const [routingStatusValueMap, setRoutingStatusValueMap] = useState([]);
  const getRoutingStatusValue = async () => {
    try {
      let data = await TLinkApi.get("/vendors/routing/status-list");
      setRoutingStatusValue(data);
      let routingStatusValueMap = [];
      data.map((value) => {
        routingStatusValueMap[value.id] = value.name;
        return true;
      });
      setRoutingStatusValueMap(routingStatusValueMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getRoutingStatusValue();
  }, []);
  return { routingStatusValue, getRoutingStatusValue, routingStatusValueMap };
};

export default GetRoutingStatus;

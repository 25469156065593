import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "assets/theme/base/colors";
import { TextField, Button, Grid, Paper } from "@mui/material";
import ReactDOM from "react-dom";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const StatusTable = ({ reconcileData, reconcileId, setReconcileModal, reconciliation }) => {
  let { textColor } = colors;
  const navigate = useNavigate();

  return ReactDOM.createPortal(
    <>
      <Grid
        sx={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(189, 189, 189,0.7)",
        }}
      ></Grid>

      <Grid
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Grid>
          <Paper sx={{ padding: "10px", height: "500px", width: "500px", position: "relative" }}>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <TableContainer component={Paper}
                sx={{
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table
                  sx={{
                    "& th": {
                      color: textColor.main,
                      fontSize: "14px",
                    },
                    "& .MuiTableCell-root": {
                      fontSize: "14px",
                      fontWeight: "400",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                    },
                  }}
                >
                  <TableHead style={{ display: "table-header-group" }}>
                  <TableRow>
                    {/* <TableCell>Token Id</TableCell> */}
                    {/* <TableCell align="center">Client Token</TableCell> */}
                    <TableCell align="center">SURVEY ID</TableCell>
                    <TableCell align="center">COMPLETES</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reconcileData?.map((value, i) => (
                    <TableRow key={i}>
                      {/* <TableCell>
                    {value?.respondentToken ? value.respondentToken : "NA"}
                  </TableCell>
                  <TableCell align="center">
                    {value.startDate ? value.startDate : "NA"}
                  </TableCell> */}
                      <TableCell align="center">{value.survey_id}</TableCell>
                      <TableCell align="center">{value.count}</TableCell>
                      {/* <TableCell
                    align="center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="rowStatus"
                      style={{
                        borderColor: !value?.matched ? "#FFA39E" : "",
                        color: !value?.matched ? "red" : "",
                        background: !value?.matched ? "#FFF1F0" : "",
                      }}
                    >
                      {value?.matched ? "Matched" : "Unmatched"}
                    </div>
                  </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Grid
              container
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              sx={{ position: "absolute", bottom: 0, right: 0 }}
            >
              <MDButton
                sx={{ margin: "15px" }}
                variant="outlined"
                color="dark"
                onClick={() => {
                  setReconcileModal(false);
                  window.location.reload();
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                sx={{ margin: "15px" }}
                variant="gradient"
                color="info"
                onClick={() => {
                  reconciliation(reconcileId);
                  navigate(`/globalreconciliation`);
                  setReconcileModal(false);
                  window.location.reload();
                }}
              >
                Reconcile & Close
              </MDButton>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>,
    document.querySelector(".editsection")
  );
};

export default StatusTable;

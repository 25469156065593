import { useState } from "react";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";
import Axios from "axios";
import Auth from "services/auth";
import { useSnackbar } from "notistack";
import { Grid, IconButton } from "@mui/material";
import PageLayout from "comps/LayoutContainers/PageLayout";
import "./index.css";
import Signin from "assets/images/icons/Signin.png";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Controller, useForm } from "react-hook-form";
import useLogo from "services/logo.service";
// import { Link } from "react-router-dom";
// import MDTypography from "components/MDTypography";

import colors from "assets/theme/base/colors";
import { getDomainName } from "services/utility";
const { background } = colors;

function Basic() {
  const { enqueueSnackbar } = useSnackbar();
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { loading, error, fullLogo, shortLogo } = useLogo(getDomainName(window.location.hostname));

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const save = async (value) => {
    try {
      const { data, status } = await Axios.post(TLinkApi.baseUrl + "/login", value);
      if (status === 200) {
        Auth.saveAuthorizationToken(data?.token);
        // enqueueSnackbar("Logged in", {
        //   variant: "success",
        // });
        navigate("/dashboard");
      }
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
      // alert("Login failed. Please check your credentials!");
    }
  };

  return (
    <PageLayout>
      <Grid
        container
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        style={{
          background: `linear-gradient(242deg, ${background.default} 0%, #CEDBFF5C 90%)`,
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Grid item xs={12} md={12} lg={5}>
          <MDBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100vh" }}
          >
            <MDBox
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
              mx={2}
              p={1}
              mb={1}
              textAlign="center"
              fontWeight="900"
              style={{
                boxShadow:
                  "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
              }}
            >
              <MDBox
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
                mx={2}
                p={2}
                mb={1}
                textAlign="center"
                fontWeight="900"
              >
                <MDBox
                  component="img"
                  mt={2}
                  mb={-2}
                  src={fullLogo}
                  alt="Admin App"
                  width="280px"
                  height="100px"
                  background="#00AEEF"
                  display="block"
                  object-fit="contain"
                />
                <MDBox textAlign="center" pt={5}>
                  <span className="welcomeCss">Welcome</span>
                  <span className="backCss"> back !</span>
                </MDBox>

                <MDBox textAlign="left" pt={1} pb={3} px={3}>
                  <form onSubmit={handleSubmit(save)} noValidate>
                    <MDBox mb={2} maxWidth="300px">
                      <label className="labelCss">
                        <span style={{ color: "red" }}>* </span>Email Address
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "Email is required!",
                        }}
                        render={({ field }) => (
                          <MDInput
                            {...field}
                            fullWidth
                            required
                            type="email"
                            {...register("email", {
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Entered value does not match email format",
                              },
                            })}
                            variant="outlined"
                            placeholder="Email"
                            error={errors?.email ? true : false}
                            helperText={errors?.email ? errors.email.message : null}
                          />
                        )}
                      />
                    </MDBox>
                    <MDBox mb={2} className="passwordInput">
                      <label className="labelCss">
                        <span style={{ color: "red" }}>* </span>Password
                      </label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: "Password is required!",
                        }}
                        render={({ field }) => (
                          <MDInput
                            {...field}
                            fullWidth
                            required
                            type={passwordShown ? "text" : "password"}
                            placeholder="Password"
                            variant="outlined"
                            error={errors?.password ? true : false}
                            helperText={errors?.password ? errors.password.message : null}
                          />
                        )}
                      />
                      <div className="eyeIcon">
                        {passwordShown === true ? (
                          <IconButton
                            onClick={() => {
                              setPasswordShown(false);
                            }}
                          >
                            <VisibilityOutlinedIcon color="secondary" />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setPasswordShown(true);
                            }}
                          >
                            <VisibilityOffOutlinedIcon color="secondary" />
                          </IconButton>
                        )}
                      </div>
                    </MDBox>
                    <MDBox mt={-2} mb={-1} textAlign="right">
                      <MDTypography variant="button" color="text">
                        <MDTypography
                          component={Link}
                          to="/forget-password"
                          variant="button"
                          color="info"
                          fontWeight="small"
                          textGradient
                        >
                          forgot password?
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={4} mb={1} display="flex" flexDirection="row" justifyContent="center">
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                        style={{
                          width: "65%",
                        }}
                      >
                        <MDBox component="img" src={Signin} width=" 23.52px" height="23.04px" mr={2} />
                        Sign in
                      </MDButton>
                    </MDBox>
                    {/*  <MDBox mt={3} mb={1} textAlign="center">
                      <MDTypography variant="button" color="text">
                        Don't have an account?{" "}
                        <MDTypography
                          component={Link}
                          to="/signup"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Sign Up
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    Don't remember passoword?{" "}
                  */}
                  </form>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Basic;

import AddGlobalReconciliation from "./components/addGlobalReconciliation";

function GlobalReconciliation() {
    return(
        <>
        <AddGlobalReconciliation/>
        </>
    )
}

export default GlobalReconciliation; 

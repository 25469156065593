import React, { useState, useEffect } from "react";
import markdownFile from "assets/docs/README.md";
import MarkdownJsx from "markdown-to-jsx";

import "../../../../global.css";

const MarkdownReader = () => {
  const [markdown, setMarkdown] = useState("");
  const customOptions = {
    overrides: {
      h1: {
        component: "h1",
        props: {
          className: "readme-custom-heading",
        },
      },
      h2: {
        component: "h2",
        props: {
          className: "readme-custom-heading",
        },
      },
      h3: {
        component: "h3",
        props: {
          className: "readme-custom-heading",
        },
      },
      ul: {
        component: "ul",
        props: {
          style: {
            marginLeft: "20px", // Adjust the left indentation as needed
          },
        },
      },
      ol: {
        component: "ol",
        props: {
          style: {
            marginLeft: "20px", // Adjust the left indentation as needed
          },
        },
      },
      a: {
        component: 'a',
        props: {
          style: {
            color: 'var(--textColor)', // Change to your desired color
          },
        },
      },
    },
  };

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(markdownFile);
        const text = await response.text();
        setMarkdown(text);
      } catch (error) {
        console.error("Error reading Markdown file:", error);
      }
    };
    fetchMarkdown();
  }, []); // Empty dependency array ensures the effect runs only once on component mount
  return (
    <div className="readme-container">
      <div className="readme-markdown-content">
        <MarkdownJsx options={customOptions}>{markdown}</MarkdownJsx>
      </div>
    </div>
  );
};

export default MarkdownReader;

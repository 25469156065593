import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Autocomplete,
  Backdrop,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Checkbox,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import GetBuyerList from "hooks/ClientBuyerList/GetBuyerList";

const ClientFilter = () => {
  const navigate = useNavigate();
  const { client, setClient, isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const { language, languageMap } = GetLanguageList();
  const { id } = useParams();
  const { buyerList, buyerListMap } = GetBuyerList(id);

  const [showAllSelectedLanguages, setShowAllSelectedLanguages] = useState(false);

  const [showAllSelectedAllowedBuyers, setShowAllSelectedAllowedBuyers] = useState(false);
  const [showAllSelectedExcludedBuyers, setShowAllSelectedExcludedBuyers] = useState(false);

  const [isAllowedBuyerIdDisabled, setIsAllowedBuyerIdDisabled] = useState(false);
  const [isExcludedBuyerIdDisabled, setIsExcludedBuyerIdDisabled] = useState(false);

  const handleAllowedBuyerIdChange = (newValue) => {
    if (newValue.length > 0) {
      setIsExcludedBuyerIdDisabled(true);
    } else {
      setIsExcludedBuyerIdDisabled(false);
    }
  };

  const handleExcludedBuyerIdChange = (newValue) => {
    if (newValue.length > 0) {
      setIsAllowedBuyerIdDisabled(true);
    } else {
      setIsAllowedBuyerIdDisabled(false);
    }
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
  } = useForm({
    defaultValues: {
      minCpi: "",
      maxCpi: "",
      minIr: "",
      maxIr: "",
      minLoi: "",
      maxLoi: "",
      minSampleRequired: "",
      collectPii: false,
      groupExists: false,
      quotaCalcTypeId: "1",
      languageIds: [],
      revenueShare: "",
      allowedbuyerIds: [],
      excludedbuyerIds: [],
    },
  });

  let groupExists = watch("groupExists");
  let collectPii = watch("collectPii");
  let languageIds = watch("languageIds");
  let allowedbuyerIds = watch("allowedbuyerIds");
  let excludedbuyerIds = watch("excludedbuyerIds");

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  useEffect(() => {
    getData();
  }, [client]);

  async function getData() {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/clients/${id}/filters`);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setIsEdit({
            disabled: true,
            edit: false,
          });
          setDataEdit(true);
          setValue("minCpi", result.data.minCpi);
          setValue("maxCpi", result.data.maxCpi);
          setValue("minIr", result.data.minIr);
          setValue("maxIr", result.data.maxIr);
          setValue("minLoi", result.data.minLoi);
          setValue("minSampleRequired", result.data.minSampleRequired);
          setValue("maxLoi", result.data.maxLoi);
          setValue("collectPii", result.data.collectPii);
          setValue("groupExists", result.data.groupExists);
          // setValue("quotaCalcTypeId", result.data.quotaCalcTypeId);
          setValue("languageIds", result.data.languageIds);
          setValue("revenueShare", result.data.revenueShare);
          setValue("allowedbuyerIds", result.data.allowedbuyerIds);
          setValue("excludedbuyerIds", result.data.excludedbuyerIds);
        } else {
          setIsEdit({
            disabled: false,
            edit: true,
          });
          setDataEdit(false);
        }
        setIsLoading(false);
      } catch (e) {
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        console.log(e);
      }
    }
  }

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.clientId = parseInt(id);

    try {
      if (dataEdit === true) {
        let result = await TLinkApi.put(`/clients/${id}/filters`, payload);
        setDataEdit(false);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });

        setIsEdit({
          disabled: false,
          edit: false,
        });
        setClient({});
        navigate("/clients");
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let result = await TLinkApi.post("/clients/filters", payload);

        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setClient({});
        navigate("/clients");
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const ingerNum = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      return;
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <Grid container spacing={3} px={2}>
          <Grid item xs={12} md={12}></Grid>
        </Grid>
        <MDBox p={2} style={{ border: "1px solid #D9D9D9", backgroundColor: "white", borderRadius: "16px" }} m={2}>
          <MDBox px={2} display="flex" justifyContent="flex-end" alignItems="center">
            <MDBox>
              {isEdit.disabled === true && (
                <IconButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              )}
            </MDBox>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                name="minCpi"
                control={control}
                rules={{
                  required: "Min Cpi is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Min Cpi"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("minCpi", {
                      min: {
                        value: 0,
                        message: "Min Cpi should be greater than 0 ",
                      },
                      max: {
                        value: getValues("maxCpi"),
                        message: "Min Cpi should be smaller than Max Cpi",
                      },
                    })}
                    placeholder="Min Cpi"
                    error={errors?.minCpi ? true : false}
                    helperText={
                      errors?.minCpi && errors.minCpi.message !== ""
                        ? errors.minCpi.message
                        : errors?.minCpi?.type === "max"
                        ? "Max Cpi cannot be smaller than Min Cpi"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="maxCpi"
                control={control}
                rules={{
                  required: "Max Cpi is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Max Cpi"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("maxCpi", {
                      min: {
                        value: getValues("minCpi"),
                        message: "Max Cpi should be greater than Min Cpi",
                      },
                    })}
                    placeholder="Max Cpi"
                    error={errors?.maxCpi ? true : false}
                    helperText={
                      errors?.maxCpi && errors.maxCpi.message !== ""
                        ? errors.maxCpi.message
                        : errors?.maxCpi?.type === "min"
                        ? "Min Cpi cannot be greater than Max Cpi"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="minIr"
                control={control}
                rules={{
                  required: "Min IR is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Min Ir"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("minIr", {
                      min: {
                        value: 0,
                        message: "Min Ir should be greater than 0 ",
                      },
                      max: {
                        value: getValues("maxIr"),
                        message: "Min Ir should be smaller than Max Ir",
                      },
                    })}
                    placeholder="Min IR"
                    error={errors?.minIr ? true : false}
                    helperText={
                      errors?.minIr && errors.minIr.message !== ""
                        ? errors.minIr.message
                        : errors?.minIr?.type === "max"
                        ? "Max Ir cannot be smaller than Min Ir"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="maxIr"
                control={control}
                rules={{
                  required: "Max IR is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Max Ir"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("maxIr", {
                      min: {
                        value: getValues("minIr"),
                        message: "Max Ir should be greater than Min Ir",
                      },
                    })}
                    placeholder="Max IR"
                    error={errors?.maxIr ? true : false}
                    helperText={
                      errors?.maxIr && errors.maxIr.message !== ""
                        ? errors.maxIr.message
                        : errors?.maxIr?.type === "min"
                        ? "Min Ir cannot be greater than Max Ir"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="minLoi"
                control={control}
                rules={{
                  required: "Min Loi is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Min Loi"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("minLoi", {
                      min: {
                        value: 0,
                        message: "Min Loi should be greater than 0 ",
                      },
                      max: {
                        value: getValues("maxLoi"),
                        message: "Min Loi should be smaller than Max Loi",
                      },
                    })}
                    placeholder="Min Loi"
                    error={errors?.minLoi ? true : false}
                    helperText={
                      errors?.minLoi && errors.minLoi.message !== ""
                        ? errors.minLoi.message
                        : errors?.minLoi?.type === "max"
                        ? "Max Loi cannot be smaller than Min Loi"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="maxLoi"
                control={control}
                rules={{
                  required: "Max Loi is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Max Loi"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("maxLoi", {
                      min: {
                        value: getValues("minLoi"),
                        message: "Max Loi should be greater than Min Loi",
                      },
                    })}
                    placeholder="Max Loi"
                    onKeyPress={(e) => {
                      ingerNum(e);
                    }}
                    error={errors?.maxLoi ? true : false}
                    helperText={
                      errors?.maxLoi && errors.maxLoi.message !== ""
                        ? errors.maxLoi.message
                        : errors?.maxLoi?.type === "min"
                        ? "Min Loi cannot be greater than Max Loi"
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="minSampleRequired"
                control={control}
                rules={{
                  required: "Min SampleRequired is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Min SampleRequired"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    {...register("minSampleRequired", {
                      pattern: {
                        value: /^[0-9 ]*$/,
                        message: "Please Enter Valid Number",
                      },
                    })}
                    variant="outlined"
                    onKeyPress={(e) => {
                      ingerNum(e);
                    }}
                    placeholder="Min SampleRequired"
                    error={errors?.minSampleRequired ? true : false}
                    helperText={errors?.minSampleRequired ? errors.minSampleRequired.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {/* {label("Language Id")} */}
              <Controller
                name="languageIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple={true}
                    disableCloseOnSelect
                    disabled={isEdit.disabled}
                    value={value} // Set the first item of language as the default value
                    onChange={(event, newValue) => {
                      let payload = newValue.map((val) => (val.id ? val.id : val));
                      onChange(payload);
                    }}
                    style={{
                      "& .MuiInputBase-input": {
                        minHeight: "20px",
                      },
                    }}
                    renderTags={(value, getTagProps) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            overflowX: "auto",
                            maxWidth: "90%",
                            maxHeight: "250px",
                            WebkitOverflowScrolling: "touch",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                        >
                          <style>
                            {`
                              /* Hide horizontal scrollbar */
                              div::-webkit-scrollbar {
                                width: 0;
                                height: 0;
                              }
                              `}
                          </style>
                          {value.slice(0, 2).map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={languageMap[option]}
                              style={{
                                height: "20px",
                              }}
                              {...getTagProps({ index })}
                            />
                          ))}
                          {value.length > 2 && !showAllSelectedLanguages && (
                            <Chip
                              variant="outlined"
                              label={`+${value.length - 2} more`}
                              style={{
                                height: "20px",
                                marginTop: "3px",
                              }}
                              onClick={() => setShowAllSelectedLanguages(true)}
                            />
                          )}

                          {showAllSelectedLanguages &&
                            value.slice(2).map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={languageMap[option]}
                                style={{
                                  height: "20px",
                                }}
                                {...getTagProps({ index })}
                              />
                            ))}
                        </div>
                      </>
                    )}
                    isOptionEqualToValue={(option, value) => option?.id == value}
                    options={language}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                          }
                          label={option.name}
                        />
                      </li>
                    )}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="outlined"
                        label="Language Id"
                        size="small"
                        error={errors?.languageIds && languageIds.length <= 0 ? true : false}
                        helperText={errors?.languageIds && languageIds.length <= 0 ? errors?.languageIds.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="allowedbuyerIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple={true}
                    disableCloseOnSelect
                    onChange={(event, newValue) => {
                      handleAllowedBuyerIdChange(newValue);
                      let payload = newValue.map((val) => (val.buyerId ? val.buyerId : val));
                      onChange(payload);
                    }}
                    disabled={isAllowedBuyerIdDisabled || isEdit.disabled || excludedbuyerIds.length > 0}
                    value={value}
                    sx={{
                      "& .MuiInputBase-input": {
                        minHeight: "20px",
                      },
                    }}
                    renderTags={(value, getTagProps) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            overflowX: "auto",
                            maxWidth: "90%",
                            maxHeight: "250px",
                            WebkitOverflowScrolling: "touch",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                        >
                          <style>
                            {`
                        /* Hide horizontal scrollbar */
                        div::-webkit-scrollbar {
                          width: 0;
                          height: 0;
                        }
                        `}
                          </style>
                          {value.slice(0, 2).map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={buyerListMap[option]}
                              style={{
                                height: "20px",
                              }}
                              {...getTagProps({ index })}
                            />
                          ))}
                          {value.length > 2 && !showAllSelectedAllowedBuyers && (
                            <Chip
                              variant="outlined"
                              label={`+${value.length - 2} more`}
                              style={{
                                height: "20px",
                                marginTop: "3px",
                              }}
                              onClick={() => setShowAllSelectedAllowedBuyers(true)}
                            />
                          )}

                          {showAllSelectedAllowedBuyers &&
                            value.slice(2).map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={buyerListMap[option]}
                                style={{
                                  height: "20px",
                                }}
                                {...getTagProps({ index })}
                              />
                            ))}
                        </div>
                      </>
                    )}
                    isOptionEqualToValue={(option, value) => option?.buyerId == value}
                    options={buyerList}
                    getOptionLabel={(option) => option.buyerName}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                          }
                          label={option.buyerName}
                        />
                      </li>
                    )}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="outlined"
                        label="Allowed Buyer Id"
                        size="small"
                        error={errors?.allowedbuyerIds && allowedbuyerIds.length <= 0 ? true : false}
                        helperText={
                          errors?.allowedbuyerIds && allowedbuyerIds.length <= 0 ? errors.allowedbuyerIds.message : null
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="excludedbuyerIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple={true}
                    disableCloseOnSelect
                    onChange={(event, newValue) => {
                      handleExcludedBuyerIdChange(newValue);
                      let payload = newValue.map((val) => (val.buyerId ? val.buyerId : val));
                      onChange(payload);
                    }}
                    disabled={isExcludedBuyerIdDisabled || isEdit.disabled || allowedbuyerIds.length > 0}
                    value={value}
                    sx={{
                      "& .MuiInputBase-input": {
                        minHeight: "20px",
                      },
                    }}
                    renderTags={(value, getTagProps) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            overflowX: "auto",
                            maxWidth: "90%",
                            maxHeight: "250px",
                            WebkitOverflowScrolling: "touch",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                          }}
                        >
                          <style>
                            {`
                            /* Hide horizontal scrollbar */
                            div::-webkit-scrollbar {
                              width: 0;
                              height: 0;
                            }
                           `}
                          </style>
                          {value.slice(0, 2).map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={buyerListMap[option]}
                              style={{
                                height: "20px",
                              }}
                              {...getTagProps({ index })}
                            />
                          ))}
                          {value.length > 2 && !showAllSelectedExcludedBuyers && (
                            <Chip
                              variant="outlined"
                              label={`+${value.length - 2} more`}
                              style={{
                                height: "20px",
                                marginTop: "3px",
                              }}
                              onClick={() => setShowAllSelectedExcludedBuyers(true)}
                            />
                          )}

                          {showAllSelectedExcludedBuyers &&
                            value.slice(2).map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={buyerListMap[option]}
                                style={{
                                  height: "20px",
                                }}
                                {...getTagProps({ index })}
                              />
                            ))}
                        </div>
                      </>
                    )}
                    isOptionEqualToValue={(option, value) => option?.buyerId == value}
                    options={buyerList}
                    getOptionLabel={(option) => option.buyerName}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                          }
                          label={option.buyerName}
                        />
                      </li>
                    )}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="outlined"
                        label="Excluded Buyer Id"
                        size="small"
                        error={errors?.excludedbuyerIds && excludedbuyerIds.length <= 0 ? true : false}
                        helperText={
                          errors?.excludedbuyerIds && excludedbuyerIds.length <= 0
                            ? errors.excludedbuyerIds.message
                            : null
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Controller
                name="quotaCalcTypeId"
                control={control}
                rules={{
                  required: "Quota Calc Type Id is Required !",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      label: {
                        color: `${formDetailsColor.primary}`,
                        fontWeight: "400",
                      },
                    }}
                    error={errors?.quotaCalcTypeId ? true : false}
                  >
                    <InputLabel>Quota Calc Type Id</InputLabel>
                    <Select
                      value={value}
                      sx={selectStyle}
                      disabled={isEdit.disabled}
                      onChange={onChange}
                      label="Quota Calc Type Id"
                    >
                      <MenuItem value={1}>1 </MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                    {errors?.quotaCalcTypeId && (
                      <FormHelperText>
                        {errors?.quotaCalcTypeId ? errors.quotaCalcTypeId.message : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid> */}

            <Grid item xs={6} md={6}>
              <Controller
                name="revenueShare"
                control={control}
                rules={{
                  required: "Revenue Share is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    label="Revenue Share(%)"
                    {...field}
                    fullWidth
                    required
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Revenue Share(%)"
                    error={errors?.revenueShare ? true : false}
                    helperText={errors?.revenueShare ? errors.revenueShare.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} display="flex" flexWrap="wrap">
              <FormControlLabel
                control={
                  <Switch
                    disabled={isEdit.disabled}
                    checked={collectPii}
                    onChange={(e, value) => {
                      setValue("collectPii", value);
                    }}
                  />
                }
                label={
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Collect Pii&nbsp;
                  </MDTypography>
                }
              />

              <FormControlLabel
                control={
                  <Switch
                    disabled={isEdit.disabled}
                    checked={groupExists}
                    onChange={(e, value) => {
                      setValue("groupExists", value);
                    }}
                  />
                }
                label={
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Group Exists&nbsp;
                  </MDTypography>
                }
              />
            </Grid>
          </Grid>
        </MDBox>

        {isEdit.disabled !== true && (
          <MDBox pt={2} px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setClient({});
                navigate("/clients");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox py={2} px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setClient({});
              navigate("/clients");
            }}
          >
            Exit
          </MDButton>
        </MDBox>
      )}
    </>
  );
};

export default ClientFilter;

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import "../../../../global.css";
import GetReconciliationList from "hooks/ReconciliationList/GetReconciliationList";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import { Icon, IconButton } from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import GetGlobalReconciliationList from "hooks/GlobalReconciliationList/getGlobalReconciliationList";

const GlobalReconciliationList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);
  const {
    reconciliation,
    isLoading,
    setIsLoading,
    getList,
    totalLength,
    setPage,
    page,
    entriesPerPage,
    setEntriesPerPage,
  } = GetGlobalReconciliationList({});

  const deleterow = async (Index) => {
    try {
      setIsLoading(true);
      const data = await TLinkApi.del(`/global/reconciliation/${Index}`);
      if (data.data.deleted == true) {
        enqueueSnackbar(data?.data?.message, {
          variant: "success",
        });
        setIsLoading(false);
        getList();
        navigate(`${location.pathname}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // const DownloadData = async (fileName) => {
  //   try {
  //     setIsLoading(true);
  //     const data = await TLinkApi.get(
  //       `/global/reconciliation/download/${fileName}`
  //     );
  //     setIsLoading(false);
  //     getList();
  //     navigate(`${location.pathname}`);
  //   } catch (err) {
  //     setIsLoading(false);
  //     console.log(err);
  //   }
  // };

  let handleStatusBackground = (value) => {
    console.log(value);
    return <div className={value.toLowerCase()}>{value}</div>;
  };

  let rows = reconciliation;

  let columns = [
    {
      accessor: "id",
      Header: "Reconcile Id",
      align: "center",
    },

    {
      accessor: "fileName",
      Header: "File Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },

    // {
    //   accessor: "typeName",
    //   Header: "Reconcile Type",
    //   sortable: true,
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      accessor: "totalMatched",
      Header: "Matched",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "totalUnMatched",
      Header: "Unmatched",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "startDate",
      Header: "Start Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return moment(row.startDate).format("MMMM Do YYYY");
      },
    },

    {
      accessor: "status",
      Header: "Status",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        console.log(row);
        return handleStatusBackground(
          row.original.status === 1
            ? "Draft"
            : row.original.status === 2
              ? "Inprogress"
              : row.original.status === 3
                ? "Completed"
                : row.original.status === 4
                  ? "Failed"
                  : ""
        );
      },
    },

    {
      accessor: "discard",
      Header: "Action",
      sortable: false,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <Stack direction="row" spacing={2}>
          <IconButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={(event) => {
              console.log(row);
              event.preventDefault();
              event.stopPropagation();
              setIsLoading(true);
              deleterow(row.original.id);
            }}
          >
            <Icon>delete_icon</Icon>
          </IconButton>
        </Stack>
      ),
    },
  ];

  // {
  //   accessor: "download",
  //   Header: "Download",
  //   sortable: false,
  //   align: "center",
  //   headerAlign: "center",
  //   Cell: ({ row }) => (
  //     <Stack direction="row" spacing={2}>
  //       <IconButton
  //         variant="outlined"
  //         color="dark"
  //         size="small"
  //         onClick={(event) => {
  //           event.preventDefault();
  //           event.stopPropagation();
  //           setIsLoading(true);
  //           DownloadData(row.original.fileName);
  //         }}
  //       >
  //         <Icon>download_icon</Icon>
  //       </IconButton>
  //     </Stack>
  //   ),
  // },

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox display="flex" justifyContent="center" mb={2}>
        <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
          <DataTable
            table={{ columns, rows }}
            isSorted
            noEndBorder
            entriesPerPage={entriesPerPage}
            canSearch={false}
            // navigateRow={`/projects/${id}/reconciliations`}
            tablePage={page}
            setTablePage={setPage}
            setEntriesPerPage={setEntriesPerPage}
            totalLength={totalLength}
            pagination={true}
            tableMaxHeight={"calc(100vh - 160px)"}
          />
        </MDBox>
      </MDBox>
    </>
  );
};

export default GlobalReconciliationList;

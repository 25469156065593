import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import DataTable from "comps/Tables/DataTable";
import "../../../../../../global.css";
import GetReconciliationList from "hooks/ReconciliationList/GetReconciliationList";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import { Icon, IconButton } from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
const ReconciliationList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const { setIsEdit } = useOutletContext();
  let { id } = useParams();
  const {
    reconciliation,
    isLoading,
    setIsLoading,
    getIndustry,
    setSearch,
    search,
    setPage,
    page,
    totalLength,
    entriesPerPage,
    setEntriesPerPage,
  } = GetReconciliationList({
    id,
  });

  const deleterow = async (Index) => {
    try {
      setIsLoading(true);
      const data = await TLinkApi.del(`/reconciliation/${Index}`);
      if (data.data.deleted == true) {
        enqueueSnackbar(data?.data?.message, {
          variant: "success",
        });
        setIsLoading(false);
        getIndustry();
        navigate(`${location.pathname}`);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    setIsEdit({
      disabled: false,
      edit: true,
    });
  }, []);

  let handleStatusBackground = (value) => {
    return <div className={value.toLowerCase()}>{value}</div>;
  };

  let rows = reconciliation;

  let columns = [
    {
      accessor: "id",
      Header: "Reconcile Id",
      align: "center",
    },
    {
      accessor: "fileName",
      Header: "File Name",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      accessor: "typeName",
      Header: "Reconcile Type",
      sortable: true,
      align: "center",
      headerAlign: "center",
    },

    {
      accessor: "matchedCount",
      Header: "Matched",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },

    {
      accessor: "unMatchedCount",
      Header: "Unmatched",
      align: "center",
      sortable: true,
      headerAlign: "center",
    },
    {
      accessor: "createdAt",
      Header: "Start Date",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("MMMM Do YYYY");
      },
    },

    {
      accessor: "statusName",
      Header: "Status",
      align: "center",
      sortable: true,
      headerAlign: "center",
      Cell: ({ row }) => {
        return handleStatusBackground(row.original.statusName);
      },
    },
    {
      accessor: "discard",
      Header: "Action",
      sortable: false,
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <Stack direction="row" spacing={2}>
          <IconButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setIsLoading(true);
              deleterow(row.values.id);
            }}
          >
            <Icon>delete_icon</Icon>
          </IconButton>
        </Stack>
      ),
    }]

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox display="flex" justifyContent="center" mb={2}>
        <MDBox py={2} mt={3} borderRadius="5px" bgColor="white" width="98%">
          <DataTable
            table={{ columns, rows }}
            isSorted
            noEndBorder
            entriesPerPage={entriesPerPage}
            canSearch={false}
            // navigateRow={`/projects/${id}/reconciliations`}
            tablePage={page}
            setTablePage={setPage}
            setEntriesPerPage={setEntriesPerPage}
            totalLength={totalLength}
            pagination={true}
            tableMaxHeight={"calc(100vh - 160px)"}
          />
        </MDBox>
      </MDBox>
      {/* <MDBox
        display="flex"
        alignItems="center"
        justifyContent={"flex-end"}
        flexDirection="row"
        mb={2}
        mx={2}
      >
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate("/projects");
          }}
        >
          Exit
        </MDButton>
      </MDBox> */}
    </>
  );
};

export default ReconciliationList;

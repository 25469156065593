import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetListOfClients = () => {
  const [clients, setClients] = useState([]);
  const [clientsMap, setClientsMap] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //   const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const getClients = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(`/clients/list`);
      setClients(data.data);
      setIsLoading(false);
      let clientMap = [];
      data?.data?.map((value) => {
        clientMap[value.id] = value.name;
        return true;
      });
      setClientsMap(clientMap);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getClients();
  }, [search]);
  return {
    clients,
    getClients,
    clientsMap,
    isLoading,
    setSearch,
    search,
  };
};

export default GetListOfClients;

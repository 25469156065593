import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import useMediaQuery from "@mui/material/useMediaQuery";

function DataTableBodyCell({ noBorder, rowWidth, align, children }) {
  const matches = useMediaQuery("(max-width:1500px)");
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={rowWidth ? 0 : 3} //3
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: `1px solid #f0f0f0`,
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content" //max-content
        color="dark"
        sx={{ verticalAlign: "middle", fontSize: matches ? "9px" : "11px" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;

import { useEffect, useState } from "react";

const useLogo = (accountSlug) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fullLogo, setFullLogo] = useState(null);
  const [shortLogo, setShortLogo] = useState(null);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const fullLogo = await import(`../assets/theme-files/${accountSlug}/full-logo.png`); // change relative path to suit your needs
        const shortLogo = await import(`../assets/theme-files/${accountSlug}/short-logo.png`); // change relative path to suit your needs

        setFullLogo(fullLogo.default);
        setShortLogo(shortLogo.default);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLogos();
  }, [accountSlug]);

  return {
    loading,
    error,
    fullLogo,
    shortLogo,
  };
};

export default useLogo;

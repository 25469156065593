import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { Backdrop, CircularProgress, Icon, IconButton } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

const VendorSupplyConfig = () => {
  const navigate = useNavigate();
  const { vendor, setVendor, isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const { id } = useParams();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      id: "",
      minCpi: "",
      maxCpi: "",
      minIr: "",
      maxIr: "",
      minLoi: "",
      maxLoi: "",
      minVcpi: "",
      maxVcpi: "",
      minConversion: "",
      minEpc: "",
      minSampleRequired: "",
    },
  });

  useEffect(() => {
    getData();
  }, [vendor]);

  async function getData() {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/vendors/supply/config/${id}`);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setDataEdit(true);
          setValue("id", result.data.id);
          setValue("minCpi", result.data.minCpi);
          setValue("maxCpi", result.data.maxCpi);
          setValue("minIr", result.data.minIr);
          setValue("maxIr", result.data.maxIr);
          setValue("minLoi", result.data.minLoi);
          setValue("maxLoi", result.data.maxLoi);
          setValue("minVcpi", result.data.minVcpi);
          setValue("maxVcpi", result.data.maxVcpi);
          setValue("minConversion", result.data.minConversion);
          setValue("minEpc", result.data.minEpc);
          setValue("minSampleRequired", result.data.minSampleRequired);
        } else {
          setDataEdit(false);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const save = async (data) => {
    setIsLoading(true);

    try {
      if (dataEdit === true) {
        let payloadId = data.id;

        let put_payload = {
          min_cpi: Number(data.minCpi),
          max_cpi: Number(data.maxCpi),
          min_ir: Number(data.minIr),
          max_ir: Number(data.maxIr),
          min_loi: Number(data.minLoi),
          max_loi: Number(data.maxLoi),
          min_vcpi: Number(data.minVcpi),
          max_vcpi: Number(data.maxVcpi),
          min_conversion: Number(data.minConversion),
          min_epc: Number(data.minEpc),
          min_sample_required: Number(data.minSampleRequired),
        };

        let result = await TLinkApi.put(
          `/vendors/supply/config/${payloadId}`,
          put_payload
        );
        setIsLoading(false);
        setVendor({});
        navigate("/vendors");
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        let post_payload = {
          vendor_id: parseInt(id),
          min_cpi: Number(data.minCpi),
          max_cpi: Number(data.maxCpi),
          min_ir: Number(data.minIr),
          max_ir: Number(data.maxIr),
          min_loi: Number(data.minLoi),
          max_loi: Number(data.maxLoi),
          min_vcpi: Number(data.minVcpi),
          max_vcpi: Number(data.maxVcpi),
          min_conversion: Number(data.minConversion),
          min_epc: Number(data.minEpc),
          min_sample_required: Number(data.minSampleRequired),
        };

        let result = await TLinkApi.post(
          "vendors/supply/config/",
          post_payload
        );
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });

        setVendor({});
        navigate("/vendors");
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  const ingerNum = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      return;
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <Grid container spacing={3} px={2}>
          <Grid item xs={12} md={12}></Grid>
        </Grid>
        <MDBox
          p={2}
          style={{
            border: "1px solid #D9D9D9",
            backgroundColor: "white",
            borderRadius: "16px",
          }}
          m={2}
        >
          <MDBox
            px={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <MDBox>
              {isEdit.disabled === true && (
                <IconButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              )}
            </MDBox>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Min Cpi"
                value={watch("minCpi")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("minCpi", {
                  min: {
                    value: 0.0001,
                    message: "Min Cpi should be greater than 0 ",
                  },
                  max: {
                    value: watch("maxCpi"),
                    message: "Min Cpi should be smaller than Max Cpi",
                  },
                })}
                placeholder="Min Cpi"
                error={errors?.minCpi ? true : false}
                helperText={
                  errors?.minCpi && errors.minCpi.message !== ""
                    ? errors.minCpi.message
                    : errors?.minCpi?.type === "max"
                    ? "Max Cpi cannot be smaller than Min Cpi"
                    : null
                }
                InputProps={{
                  inputProps: {
                    step: 0.01,
                  },
                }}
                onBlur={(e) => {
                  const roundedValue = parseFloat(e.target.value).toFixed(2);
                  setValue("minCpi", roundedValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Max Cpi"
                value={watch("maxCpi")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("maxCpi", {
                  min: {
                    value: watch("minCpi"),
                    message: "Max Cpi should be greater than Min Cpi",
                  },
                })}
                placeholder="Max Cpi"
                error={errors?.maxCpi ? true : false}
                helperText={
                  errors?.maxCpi && errors.maxCpi.message !== ""
                    ? errors.maxCpi.message
                    : errors?.maxCpi?.type === "min"
                    ? "Min Cpi cannot be greater than Max Cpi"
                    : null
                }
                InputProps={{
                  inputProps: {
                    step: 0.01,
                  },
                }}
                onBlur={(e) => {
                  const roundedValue = parseFloat(e.target.value).toFixed(2);
                  setValue("maxCpi", roundedValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Min Ir"
                value={watch("minIr")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("minIr", {
                  min: {
                    value: 0.0001,
                    message: "Min Ir should be greater than 0 ",
                  },
                  max: {
                    value: watch("maxIr"),
                    message: "Min Ir should be smaller than Max Ir",
                  },
                })}
                placeholder="Min IR"
                error={errors?.minIr ? true : false}
                helperText={
                  errors?.minIr && errors.minIr.message !== ""
                    ? errors.minIr.message
                    : errors?.minIr?.type === "max"
                    ? "Max Ir cannot be smaller than Min Ir"
                    : null
                }
                InputProps={{
                  inputProps: {
                    step: 0.01,
                  },
                }}
                onBlur={(e) => {
                  const roundedValue = parseFloat(e.target.value).toFixed(2);
                  setValue("minIr", roundedValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Max Ir"
                value={watch("maxIr")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("maxIr", {
                  min: {
                    value: watch("minIr"),
                    message: "Max Ir should be greater than Min Ir",
                  },
                })}
                placeholder="Max IR"
                error={errors?.maxIr ? true : false}
                helperText={
                  errors?.maxIr && errors.maxIr.message !== ""
                    ? errors.maxIr.message
                    : errors?.maxIr?.type === "min"
                    ? "Min Ir cannot be greater than Max Ir"
                    : null
                }
                InputProps={{
                  inputProps: {
                    step: 0.01,
                  },
                }}
                onBlur={(e) => {
                  const roundedValue = parseFloat(e.target.value).toFixed(2);
                  setValue("maxIr", roundedValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Min Loi"
                value={watch("minLoi")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("minLoi", {
                  min: {
                    value: 0.0001,
                    message: "Min Loi should be greater than 0 ",
                  },
                  max: {
                    value: watch("maxLoi"),
                    message: "Min Loi should be smaller than Max Loi",
                  },
                  validate: (value) => {
                    if (value % 1 !== 0) {
                      return "Please enter an integer value for Min Loi";
                    }
                    return true;
                  },
                })}
                placeholder="Min Loi"
                error={errors?.minLoi ? true : false}
                helperText={
                  errors?.minLoi && errors.minLoi.message !== ""
                    ? errors.minLoi.message
                    : errors?.minLoi?.type === "max"
                    ? "Max Loi cannot be smaller than Min Loi"
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Max Loi"
                value={watch("maxLoi")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("maxLoi", {
                  min: {
                    value: watch("minLoi"),
                    message: "Max Loi should be greater than Min Loi",
                  },
                  validate: (value) => {
                    if (value % 1 !== 0) {
                      return "Please enter an integer value for Max Loi";
                    }
                    return true;
                  },
                })}
                placeholder="Max Loi"
                error={errors?.maxLoi ? true : false}
                helperText={
                  errors?.maxLoi && errors.maxLoi.message !== ""
                    ? errors.maxLoi.message
                    : errors?.maxLoi?.type === "min"
                    ? "Min Loi cannot be greater than Max Loi"
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Min VCpi"
                value={watch("minVcpi")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("minVcpi", {
                  min: {
                    value: 0,
                    message: "Min VCpi should be greater than 0 ",
                  },
                  max: {
                    value: watch("maxVcpi"),
                    message: "Min VCpi should be smaller than Max VCpi",
                  },
                })}
                placeholder="Min VCpi"
                error={errors?.minVcpi ? true : false}
                helperText={
                  errors?.minVcpi && errors.minVcpi.message !== ""
                    ? errors.minVcpi.message
                    : errors?.minVcpi?.type === "max"
                    ? "Max VCpi cannot be smaller than Min VCpi"
                    : null
                }
                InputProps={{
                  inputProps: {
                    step: 0.01,
                  },
                }}
                onBlur={(e) => {
                  const roundedValue = parseFloat(e.target.value).toFixed(2);
                  setValue("minVcpi", roundedValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDInput
                label="Max VCpi"
                value={watch("maxVcpi")}
                fullWidth
                disabled={isEdit.disabled}
                type="number"
                autoComplete="off"
                variant="outlined"
                {...register("maxVcpi", {
                  min: {
                    value: watch("minVcpi"),
                    message: "Max VCpi should be greater than Min VCpi",
                  },
                })}
                placeholder="Max VCpi"
                error={errors?.maxVcpi ? true : false}
                helperText={
                  errors?.maxVcpi && errors.maxVcpi.message !== ""
                    ? errors.maxVcpi.message
                    : errors?.maxVcpi?.type === "min"
                    ? "Min VCpi cannot be greater than Max VCpi"
                    : null
                }
                InputProps={{
                  inputProps: {
                    step: 0.01,
                  },
                }}
                onBlur={(e) => {
                  const roundedValue = parseFloat(e.target.value).toFixed(2);
                  setValue("maxVcpi", roundedValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
name="minSampleRequired"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]+$/,
                    message:
                      "Please enter a valid integer for Min SampleRequired",
                  },
                }}
                render={({ field }) => (
                  <MDInput
                    label="Min SampleRequired"
                    {...field}
                    fullWidth
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    {...register("minSampleRequired", {
                      min: {
                        value: 0.0001,
                        message: "Min Sample Required should be greater than 0",
                      },
                    })}
                    variant="outlined"
                    placeholder="Min SampleRequired"
                    error={errors?.minSampleRequired ? true : false}
                    helperText={
                      errors?.minSampleRequired
                        ? errors.minSampleRequired.message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="minConversion"
                control={control}
                render={({ field }) => (
                  <MDInput
                    label="Min Converson"
                    {...field}
                    fullWidth
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("minConversion", {
                      min: {
                        value: 0.0001,
                        message: "Min Converson should be greater than 0 ",
                      },
                    })}
                    placeholder="Min Converson"
                    error={errors?.minConversion ? true : false}
                    helperText={
                      errors?.minConversion &&
                      errors.minConversion.message !== ""
                        ? errors.minConversion.message
                        : null
                    }
                    InputProps={{
                      inputProps: {
                        step: 0.01,
                      },
                    }}
                    onBlur={(e) => {
                      const roundedValue = parseFloat(e.target.value).toFixed(
                        2
                      );
                      setValue("minConversion", roundedValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="minEpc"
                control={control}
                render={({ field }) => (
                  <MDInput
                    label="Min Epc"
                    {...field}
                    fullWidth
                    disabled={isEdit.disabled}
                    type="number"
                    autoComplete="off"
                    variant="outlined"
                    {...register("minEpc", {
                      min: {
                        value: 0.0001,
                        message: "Min Epc should be greater than 0 ",
                      },
                    })}
                    placeholder="Min Epc"
                    error={errors?.minEpc ? true : false}
                    helperText={
                      errors?.minEpc && errors.minEpc.message !== ""
                        ? errors.minEpc.message
                        : null
                    }
                    InputProps={{
                      inputProps: {
                        step: 0.01,
                      },
                    }}
                    onBlur={(e) => {
                      const roundedValue =
                        Math.round(Number(e.target.value) * 100) / 100;
                      setValue("minEpc", roundedValue);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>

        {isEdit.disabled !== true && (
          <MDBox
            pt={2}
            px={2}
            mb={4}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
                navigate("/vendors");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          py={2}
          px={2}
          mb={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
              navigate("/vendors");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
              navigate("/vendors");
            }}
          >
            Save
          </MDButton>
        </MDBox>
      )}
    </>
  );
};

export default VendorSupplyConfig;

let sampleData = [
  {
    columns: [{ label: "token", value: "token" }],
    content: [
      { token: "22e8728645234140abff54fbbd7632f9" },
      { token: "609405b5e4b548109bc7fb004c8be675" },
    ],
  },
];

export default sampleData;

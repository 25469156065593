import { Icon, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QualificationChart from "comps/Cards/QualificationChart";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

const QualificationList = ({
  val,
  index,
  getQualificationQuestions,
  questionStatus,
  isEdit,
  qualificationQuestions,
  setQualificationQuestions,
  setIsEdit,
}) => {
  const [i, setI] = useState(null);
  let { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const [singleQualification, setsingleQualification] = useState([]);

  const activeQualification = async ({ val, value }) => {
    try {
      let result = await TLinkApi.put(`survey/qualification/${val.surveyQualificationId}`, {
        isActive: value,
      });
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      getQualificationQuestions();
      setI(null);
    } catch (e) {
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };
  
  const getAnswers = async () => {
    try {
      let response = await TLinkApi.get(
        `/qualifications/${val.question.languageId}/${val.qualificationId}/${val.question.qualificationQuestionId}`
      );
      setsingleQualification(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <MDBox mt={3} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }} key={index}>
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap="5px"
        style={{
          fontSize: "14px",
          fontWeight: "500",
          color: "black",
        }}
      >
        <MDBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { xs: "10%", md: "10%", lg: "5%" } }}
          style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
        >
          <IconButton
            sx={{ transform: index === i ? "rotate(180deg)" : "" }}
            onClick={() => {
              if (index === null) {
                setI(index);
              } else if (index !== i) {
                getAnswers();
                setI(index);
              } else {
                setI(null);
              }
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </MDBox>
        <MDBox
          width="15%"
          px={1}
          sx={{
            width: {
              xs: "10%",
              md: "20%",
              lg: "15%",
            },
            fontSize: { xs: "10px", md: "12px", lg: "15px" },
          }}
          style={{
            borderRight: "1px solid rgba(0, 0, 0, 0.06)",
          }}
        >
          {val.qualificationCategoryTypeId === 1 ? "Standard" : "Custom"}
        </MDBox>
        <MDBox
          px={1}
          width="70%"
          height="auto"
          flexWrap="wrap"
          display="flex"
          sx={{
            width: { xs: "40%", md: "50%", lg: "70%" },
            fontSize: { xs: "10px", md: "12px", lg: "15px" },
          }}
          style={{
            borderRight: "1px solid rgba(0, 0, 0, 0.06)",
          }}
        >
          {val.question.questionText}
        </MDBox>
        <MDBox
          width="10%"
          sx={{
            width: { xs: "20%", md: "20%", lg: "10%" },
            fontSize: { xs: "10px", md: "12px", lg: "15px" },
          }}
          style={{ borderleft: "1px solid rgba(0, 0, 0, 0.06)" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Switch
            color="secondary"
            checked={val.isActive}
            disabled={edit === false}
            onChange={(e, value) => {
              activeQualification({ val, value });
            }}
          />
          <IconButton
            color="dark"
            size="small"
            onClick={() => {
              setEdit(!edit);
              if (index === null) {
                setI(index);
              } else if (index !== i) {
                getAnswers();
                setI(index);
              } else {
                setI(null);
              }
            }}
          >
            <Icon>border_color_icon</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox style={{ display: i === index ? "" : "none" }}>
        <QualificationChart
          val={val}
          index={index}
          qualificationQuestions={qualificationQuestions}
          setQualificationQuestions={setQualificationQuestions}
          getQualificationQuestions={getQualificationQuestions}
          setI={setI}
          edit={edit}
          setEdit={setEdit}
          singleQualification={singleQualification}
          setsingleQualification={setsingleQualification}
        />
      </MDBox>
    </MDBox>
  );
};

export default QualificationList;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetLanguageList = () => {
  const [language, setLanguage] = useState([]);
  const [languageMap, setLanguageMap] = useState([]);
  const getLanguage = async () => {
    try {
      let data = await TLinkApi.get("/masters/language");
      setLanguage(data.data);
      let langMap = [];
      data.data.map((value) => {
        langMap[value.id] = value.name;
        return true;
      });
      setLanguageMap(langMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getLanguage();
  }, []);
  return { language, getLanguage, languageMap };
};

export default GetLanguageList;

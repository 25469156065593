import {
  Backdrop,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Switch,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

import Colors from "../../../../assets/theme/base/colors";
const { primary } = Colors;

const RedirectType = () => {
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  let { setVendor, setIsEdit, isEdit } = useOutletContext();
  const [dataEdit, setDataEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      variable_1: "",
      variable_2: "",
      variable_3: "",
      variable_4: "",
      incomingAutoPunch: false,
      supplierQualificationParam: false,
      vendorCompleted: "",
      vendorQuotaFull: "",
      vendorTerminate: "",
      vendorSecurity: "",
      s2sCompleted: "",
      s2sQuotaFull: "",
      s2sTerminate: "",
      s2sSecurity: "",
      s2sEnable: false,
    },
  });

  let s2sEnable = watch("s2sEnable");
  let incomingAutoPunch = watch("incomingAutoPunch");
  let supplierQualificationParam = watch("supplierQualificationParam");

  let { id } = useParams();

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/vendors/variable/${id}`);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setIsEdit({
            disabled: true,
            edit: false,
          });
          setValue("variable_1", result.data.variable_1);
          setValue("variable_2", result.data.variable_2);
          setValue("variable_3", result.data.variable_3);
          setValue("variable_4", result.data.variable_4);
          setValue("vendorCompleted", result.data.vendorCompleted);
          setValue("vendorQuotaFull", result.data.vendorQuotaFull);
          setValue("vendorTerminate", result.data.vendorTerminate);
          setValue("vendorSecurity", result.data.vendorSecurity);
          setValue("s2sCompleted", result.data.s2sCompleted);
          setValue("s2sQuotaFull", result.data.s2sQuotaFull);
          setValue("s2sTerminate", result.data.s2sTerminate);
          setValue("s2sSecurity", result.data.s2sSecurity);
          setValue("s2sEnable", result.data.s2sEnable);
          setValue("incomingAutoPunch", result.data.incomingAutoPunch);
          setValue(
            "supplierQualificationParam",
            result.data.supplierQualificationParam
          );
          setDataEdit(true);
          setIsLoading(false);
        } else {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };
    payload.vendorId = id;

    try {
      if (dataEdit === true) {
        let result = await TLinkApi.put(`/vendors/variable/${id}`, payload);
        setDataEdit(false);
        setIsLoading(false);
        setVendor({});
        enqueueSnackbar(result?.message, {
          variant: "success",
        });

        navigate(`/vendors/${id}/vendorEncryption/edit`);
      } else {
        let result = await TLinkApi.post("/vendors/variable", payload);
        setIsLoading(false);
        setVendor({});
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/vendors/${id}/vendorEncryption/edit`);
      }
    } catch (e) {
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (s2sEnable === false) {
      setValue("s2sCompleted", "");
      setValue("s2sQuotaFull", "");
      setValue("s2sTerminate", "");
      setValue("s2sSecurity", "");
    }
  }, [s2sEnable]);

  return (
    <>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox bgColor="white" p={1} m={2} borderRadius="16px">
          <MDBox
            px={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <MDBox>
              {isEdit.disabled === true && (
                <IconButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              )}
            </MDBox>
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            m={2}
          >
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Vendor Details"}
            </MDTypography>

            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable_1"
                    control={control}
                    rules={{
                      required: "Variable 1 is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        disabled={isEdit.disabled}
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        label="Token Exchange"
                        error={errors?.variable_1 ? true : false}
                        helperText={
                          errors?.variable_1 ? errors.variable_1.message : null
                        }
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      <div>
                        <span> Instruction:</span>
                        <br />
                        <span>Supplier Token Variable</span>
                      </div>
                    }
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>

              {/* Second set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable_2"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        label="Supplier User Id Exchange"
                        error={errors?.variable_2 ? true : false}
                        helperText={
                          errors?.variable_2 ? errors.variable_2.message : null
                        }
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      <div>
                        <span> Instruction:</span>
                        <br />
                        <span>Supplier User Id</span>
                      </div>
                    }
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>

              {/* Third set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable_3"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        type="text"
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="Additional Variable 1"
                        error={errors?.variable_3 ? true : false}
                        helperText={
                          errors?.variable_3 ? errors.variable_3.message : null
                        }
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      <div>
                        <span> Instruction:</span>
                        <br />
                        <span>Additional Variable if needed from supplier</span>
                      </div>
                    }
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>

              {/* Fourth set of variables */}
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="variable_4"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        type="text"
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="Additional Variable 2"
                        error={errors?.variable_4 ? true : false}
                        helperText={
                          errors?.variable_4 ? errors.variable_4.message : null
                        }
                      />
                    )}
                  />
                  <Tooltip
                    title={
                      <div>
                        <span> Instruction:</span>
                        <br />
                        <span>Additional Variable if needed from supplier</span>
                      </div>
                    }
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isEdit.disabled}
                      checked={incomingAutoPunch}
                      onChange={(e, value) => {
                        setValue("incomingAutoPunch", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;&nbsp;Demographic Autopunch
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isEdit.disabled}
                      checked={supplierQualificationParam}
                      onChange={(e, value) => {
                        setValue("supplierQualificationParam", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;&nbsp;Use Supplier Mapping
                    </MDTypography>
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            m={2}
          >
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Redirection URLs"}
            </MDTypography>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="vendorCompleted"
                  control={control}
                  rules={{
                    required: `Vendor Completed is required!`,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      label="Vendor Completed"
                      {...register("vendorCompleted", {
                        pattern: {
                          value:
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorCompleted ? true : false}
                      helperText={
                        errors?.vendorCompleted
                          ? errors.vendorCompleted.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="vendorQuotaFull"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      label="Vendor QuotaFull"
                      {...register("vendorQuotaFull", {
                        pattern: {
                          value:
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorQuotaFull ? true : false}
                      helperText={
                        errors?.vendorQuotaFull
                          ? errors.vendorQuotaFull.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="vendorTerminate"
                  control={control}
                  rules={{
                    required: `Vendor Terminate is required!`,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      label="Vendor Terminate"
                      {...register("vendorTerminate", {
                        pattern: {
                          value:
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorTerminate ? true : false}
                      helperText={
                        errors?.vendorTerminate
                          ? errors.vendorTerminate.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="vendorSecurity"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      label="Vendor Security"
                      {...register("vendorSecurity", {
                        pattern: {
                          value:
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid URl ",
                        },
                      })}
                      error={errors?.vendorSecurity ? true : false}
                      helperText={
                        errors?.vendorSecurity
                          ? errors.vendorSecurity.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            m={2}
          >
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"S2S URLs"}
              </MDTypography>
              <div style={{ color: `${primary.main}` }}>
                <Switch
                  checked={s2sEnable}
                  disabled={isEdit.disabled}
                  onChange={(e, value) => {
                    setValue("s2sEnable", value);
                  }}
                />
              </div>
            </MDBox>
            {s2sEnable === true && (
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="s2sCompleted"
                    control={control}
                    rules={{
                      required: `S2S Completed is required!`,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        required
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="S2S Completed"
                        {...register("s2sCompleted", {
                          pattern: {
                            value:
                              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            message: "invalid URl ",
                          },
                        })}
                        error={errors?.s2sCompleted ? true : false}
                        helperText={
                          errors?.s2sCompleted
                            ? errors.s2sCompleted.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="s2sQuotaFull"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="S2S QuotaFull"
                        {...register("s2sQuotaFull", {
                          pattern: {
                            value:
                              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            message: "invalid URl ",
                          },
                        })}
                        error={errors?.s2sQuotaFull ? true : false}
                        helperText={
                          errors?.s2sQuotaFull
                            ? errors.s2sQuotaFull.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="s2sTerminate"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        required
                        label="S2S Terminate"
                        {...register("s2sTerminate", {
                          pattern: {
                            value:
                              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            message: "invalid URl ",
                          },
                        })}
                        error={errors?.s2sTerminate ? true : false}
                        helperText={
                          errors?.s2sTerminate
                            ? errors.s2sTerminate.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="s2sSecurity"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        autoComplete="off"
                        variant="outlined"
                        label="S2S Security"
                        {...register("s2sSecurity", {
                          pattern: {
                            value:
                              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            message: "invalid URl ",
                          },
                        })}
                        error={errors?.s2sSecurity ? true : false}
                        helperText={
                          errors?.s2sSecurity
                            ? errors.s2sSecurity.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox
            px={2}
            mb={4}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                navigate("/vendors");
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          px={2}
          mb={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              navigate("/vendors");
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              navigate(`/vendors/${id}/vendorEncryption/edit`);
            }}
          >
            Save
          </MDButton>
        </MDBox>
      )}
    </>
  );
};

export default RedirectType;

import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import MDProgressRoot from "components/MDProgress/MDProgressRoot";
import MDBox from "components/MDBox";

const MDProgress = forwardRef(({ variant, color, value, label, ...rest }, ref) => (
  <MDBox display="flex" alignItems="center" flexDirection="row" gap="10px">
    <MDBox bgColor="#f1f1f1" width="100%">
      <MDProgressRoot
        {...rest}
        ref={ref}
        variant="determinate"
        value={value}
        ownerState={{ color, value, variant }}
      />
    </MDBox>
    {label && (
      <MDTypography variant="button" fontWeight="medium" color="text" fontSize="10px">
        {value >= 100 ? 100 : value}%
      </MDTypography>
    )}
  </MDBox>
));

// Setting default values for the props of MDProgress
MDProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: false,
};

// Typechecking props for the MDProgress
MDProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "headingColor",
    "error",
    "light",
    "textColor",
    "dark",
  ]),
  value: PropTypes.number,
  label: PropTypes.bool,
};

export default MDProgress;

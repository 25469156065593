// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 90px;
  line-height: 116px;
  color: #344767;
  padding: 80px;
  text-align: left;
  margin-left: 110px;
}

.labelCss {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.welcomeCss {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 24px;
  color: #000000;
}

.backCss {
  font-family: "Futura";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 24px;
  color: #000000;
}

.passwordInput {
  position: relative;
}

.eyeIcon {
  position: absolute;
  right: 7px;
  top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/authentication/sign-in/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX","sourcesContent":[".leftText {\r\n  font-family: \"Montserrat\";\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  font-size: 90px;\r\n  line-height: 116px;\r\n  color: #344767;\r\n  padding: 80px;\r\n  text-align: left;\r\n  margin-left: 110px;\r\n}\r\n\r\n.labelCss {\r\n  font-family: \"Roboto\";\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 14px;\r\n  line-height: 22px;\r\n  color: #000000;\r\n}\r\n\r\n.welcomeCss {\r\n  font-family: \"Montserrat\";\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-size: 36px;\r\n  line-height: 24px;\r\n  color: #000000;\r\n}\r\n\r\n.backCss {\r\n  font-family: \"Futura\";\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  font-size: 36px;\r\n  line-height: 24px;\r\n  color: #000000;\r\n}\r\n\r\n.passwordInput {\r\n  position: relative;\r\n}\r\n\r\n.eyeIcon {\r\n  position: absolute;\r\n  right: 7px;\r\n  top: 32px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Grid, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import colors from "assets/theme/base/colors";

function MinMax({ question, updateQuestion, i, quotaQuestions, setQuotaError }) {
  useEffect(() => {
    let error = quotaQuestions[i]?.selectedRange?.some(
      (val) =>
        val.min === "" ||
        val.max === "" ||
        Number(val.min) > Number(val.max) ||
        Number(val.min) < 10 ||
        Number(val.max) > 100
    );
    setQuotaError(error);
  }, [quotaQuestions]);

  return (
    <MDBox p={2.5} fontSize="15px" bgColor={colors.tableRow.data} mt={1}>
      <Grid container spacing={1} style={{ paddingLeft: "2px" }}>
        <Grid xs={10} md={10} pb={2}>
          <MDBox display="flex" alignItems="center" gap="20px" flexWrap="wrap" mt={1}>
            <MDBox display="flex" alignItems="center" ml={-2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
              >
                &nbsp;&nbsp;Q:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="dark"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1.5, mt: 0 }}
              >
                &nbsp;&nbsp;{question.question.questionText}&nbsp;
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>

      {question.selectedRange.map((value, index) => (
        <Grid container spacing={4} ml={-1} mb={2}>
          <Grid item xs={12} md={5}>
            <MDInput
              type="number"
              autoComplete="off"
              variant="outlined"
              label="min"
              fullWidth
              required
              value={value.min}
              onChange={(e) => {
                let data = [...quotaQuestions];
                data[i].selectedRange[index].min = e.target.value;
                updateQuestion("quotaQuestions", data);
              }}
              error={value.min === "" ? true : Number(value.min) < 10 ? true : false}
              helperText={
                value.min === ""
                  ? "This field is Required"
                  : Number(value.min) < 10
                  ? "This value can not be less then 10"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <MDInput
              type="number"
              fullWidth
              required
              value={value.max}
              onChange={(e) => {
                let data = [...quotaQuestions];
                data[i].selectedRange[index].max = e.target.value;
                updateQuestion("quotaQuestions", data);
              }}
              autoComplete="off"
              variant="outlined"
              label="max"
              error={
                value.max === ""
                  ? true
                  : Number(value.min) > Number(value.max)
                  ? true
                  : Number(value.max) > 100
                  ? true
                  : false
              }
              helperText={
                value.max === ""
                  ? "This field is Required"
                  : Number(value.max) > 100
                  ? "This value can not be greater than 100"
                  : Number(value.min) > Number(value.max)
                  ? "This is not be less then min"
                  : ""
              }
            />
          </Grid>
        </Grid>
      ))}
    </MDBox>
  );
}

export default MinMax;

import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetSurveyReport = ({ id }) => {
  const [surveyReport, SetSurveyReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSurveyReport = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`/survey/${id}/survey-report`);
      SetSurveyReport(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSurveyReport();
  }, [id]);

  return { getSurveyReport, surveyReport, isLoading };
};

export default GetSurveyReport;

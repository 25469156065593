import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import TLinkApi from "services/tlink.api";
import GetRoutingStatus from "hooks/RoutingStatusList/GetRoutingStatusList";

import Colors from "../../../../assets/theme/base/colors";
const { primary, formDetailsColor } = Colors;

const RoutingConfig = () => {
  const navigate = useNavigate();
  const [routingEdit, setRoutingEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { routingStatusValue } = GetRoutingStatus();
  let { vendor, setVendor, setIsEdit, isEdit } = useOutletContext();

  let { enqueueSnackbar } = useSnackbar();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      routingEnable: false,
      routingTime: "",
      noOfAttempts: "",
      startRouting: false,
      routingStatus: [],
    },
  });

  let routingEnable = watch("routingEnable");
  let startRouting = watch("startRouting");

  let { id } = useParams();

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setIsLoading(true);
    if (id) {
      try {
        let result = await TLinkApi.get(`/vendors/${id}/routing`);
        setIsLoading(false);
        if (result.status !== 404 && Object.keys(result.data).length > 0) {
          setRoutingEdit(true);
          setIsEdit({
            disabled: true,
            edit: false,
          });
          setValue("routingEnable", result.data.routingEnable);
          setValue("noOfAttempts", result.data.noOfAttempts);
          setValue("routingTime", result.data.routingTime);
          setValue("startRouting", result.data.startRouting);
          setValue("routingStatus", result.data.routingStatus);
        } else {
          setRoutingEdit(false);
          setIsEdit({
            disabled: false,
            edit: true,
          });
        }
      } catch (e) {
        setRoutingEdit(false);
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
      }
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {};
    if (data.routingEnable) {
      payload = {
        noOfAttempts: parseInt(data.noOfAttempts),
        routingEnable: data.routingEnable,
        routingTime: parseInt(data.routingTime),
        vendorId: parseInt(id),
        startRouting: data.startRouting,
        routingStatus: data.routingStatus,
      };
    } else {
      payload = {
        noOfAttempts: null,
        routingEnable: data.routingEnable,
        routingTime: null,
        vendorId: parseInt(id),
        startRouting: false,
        routingStatus: [],
      };
    }
    try {
      if (routingEdit === true) {
        let result = await TLinkApi.put(`/vendors/${id}/routing`, payload);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        // setVendor({});
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        if (vendor.isApi) navigate(`/vendors/${id}/suppliermargin/edit`);
        else navigate(`/vendors`);
      } else {
        let result = await TLinkApi.post(`/vendors/routing`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsLoading(false);
        // setVendor({});
        setIsEdit({
          disabled: false,
          edit: false,
        });
        if (vendor.isApi) navigate(`/vendors/${id}/suppliermargin/edit`);
        else navigate(`/vendors`);
      }
    } catch (e) {
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const ingerNum = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      return;
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox bgColor="white" height="400px" borderRadius="16px" p={2} m={2}>
          {isEdit.disabled === true && (
            <MDBox pt={2} px={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            </MDBox>
          )}
          <MDBox
            p={2}
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: "16px",
            }}
            m={2}
          >
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              mb={3}
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"Routing Enable"}
              </MDTypography>
              <div style={{ color: `${primary.main}` }}>
                <Switch
                  disabled={isEdit.disabled}
                  checked={routingEnable}
                  onChange={(e, value) => {
                    setValue("routingEnable", value);
                  }}
                />
              </div>
            </MDBox>

            {routingEnable === true && (
              <Grid container spacing={3} px={2}>
                <Grid item xs={12} md={6} lg={2}>
                  <div style={{ position: "relative" }}>
                    <Controller
                      name="routingTime"
                      control={control}
                      rules={{
                        required: "Routing Time is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          style={{ background: "#FAFAFA" }}
                          {...field}
                          fullWidth
                          required
                          onKeyPress={(e) => {
                            ingerNum(e);
                          }}
                          type="number"
                          lineHeight={2}
                          autoComplete="off"
                          variant="outlined"
                          label="Routing Time"
                          disabled={isEdit.disabled}
                          error={errors?.routingTime ? true : false}
                          helperText={errors?.routingTime ? errors.routingTime.message : null}
                        />
                      )}
                    />
                    <span
                      style={{
                        position: "absolute",
                        bottom: errors?.routingTime ? "26px" : "8px",
                        right: "23px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: " 400",
                        fontSize: "14px",
                        lineWeight: "22px",
                      }}
                    >
                      sec
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <Controller
                    name="noOfAttempts"
                    control={control}
                    rules={{
                      required: "Number of Attempt is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        style={{ background: "#FAFAFA" }}
                        {...field}
                        fullWidth
                        required
                        type="number"
                        autoComplete="off"
                        onKeyPress={(e) => {
                          ingerNum(e);
                        }}
                        variant="outlined"
                        label="No. of Attempts"
                        disabled={isEdit.disabled}
                        error={errors?.noOfAttempts ? true : false}
                        helperText={errors?.noOfAttempts ? errors.noOfAttempts.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  {/* <InputLabel>Routing Status</InputLabel> */}
                  <Controller
                    name="routingStatus"
                    control={control}
                    rules={{
                      required: "Routing Status is required!",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          label: {
                            color: `${formDetailsColor.primary}`, //TODO: hardcoded color
                            fontWeight: "400",
                          },
                        }}
                        required
                        error={errors?.routingStatus ? true : false}
                      >
                        <InputLabel>Routing Status</InputLabel>
                        <Select
                          multiple
                          value={value}
                          label="Routing Status"
                          disabled={isEdit.disabled}
                          onChange={onChange}
                        >
                          {routingStatusValue?.map((value, i) => (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.routingStatus && (
                          <FormHelperText>{errors?.routingStatus ? errors.routingStatus.message : null}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isEdit.disabled}
                        checked={startRouting}
                        onChange={(e, value) => {
                          setValue("startRouting", value);
                        }}
                      />
                    }
                    label={
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Start Routing&nbsp;
                      </MDTypography>
                    }
                  />
                </Grid>
              </Grid>
            )}
          </MDBox>
        </MDBox>
        {isEdit.disabled === false && (
          <MDBox px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                navigate("/vendors");
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox px={2} mb={4} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
              navigate("/vendors");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              navigate(`/vendors/${id}/suppliermargin/edit`);
              setIsEdit({
                disabled: false,
                edit: false,
              });
              // setVendor({});
            }}
          >
            Save
          </MDButton>
        </MDBox>
      )}
    </>
  );
};

export default RoutingConfig;
